import React, { useEffect, useState, } from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MobileTitle } from './MobileTitle';
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { scrollToElement } from '../Utils'
import { authorized } from '../UtilsInthem'
import { ConsensoPre } from './ConsensoPre'
import { ConsensoStorico } from './ConsensoStorico'
import { ConsensoForm } from './ConsensoForm'
//import MyAxios from "../MyAxios";
import { Loading, MostButton, } from "../components/MostComponents";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const Consenso = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [secondTab, setSecondTab] = useState("preliminari")
    const [tabValue, setTabValue] = useState(null)
    const [tab, setTab] = useState(null)
    const [insertingFirst, setInsertingFirst] = useState(false)

    useEffect(() => {
        let tabNumber = 0
        if (userInfo.c_db !== null && userInfo.c_db_p !== null) {
            tabNumber = 1
            setSecondTab("consensi")
        }
        setTabValue(tabNumber)
        setTab("tab"+tabNumber)
        setLoading(false)
    }, [userInfo.c_db,userInfo.c_db_p,])

    const changeTab = (tabNumber,fromPreSubmit) => {
        setTabValue(tabNumber)
        setTab("tab"+tabNumber)
        scrollToElement("startingDiv")
    }

    const handleTabChange = (e,tabNumber) => {
        changeTab(tabNumber)
    }

    const viewConsensi = () => {
        setSecondTab("consensi")
        setInsertingFirst(true)
    }

    const Informazioni = () => {
        const { t } = useTranslation();
        // xxxxx inizio
        const url_video = "https://xxxx/xxxx"
        const url_informazioni = "https://xxxx/xxxx"
        const telefono = "xxx"
        // xxxxx fine
        const url_login = window.location.protocol+"//"+window.location.host+"/loginp"

        const goOn = () => {
            changeTab(1)
        }

        return (
        <Container component="main" maxWidth="md" className="margintop30">
            <h2> {t("Consenso per la raccolta dei dati")} </h2>
            <div className="blackColor">
    <p>{t("dati conservati ai fini")}</p>
    <p>{t("diritto di decidere")}</p>
    <p>{t("ho letto informazioni")} <a href={url_video} target="_blank" rel="noreferrer">{t("video informativo di introduzione")}</a>.</p>
    <p>{t("tramite consenso dinamico")}</p>
    <p>{t("resta chiaro che")}</p>
    <p>{t("posso chiedere informazioni")}:</p>
    <p>
    <i>Fondazione per la Ricerca sull’Anemia <br />
    ed Emoglobinopatie in Italia - For Anemia <br />
    Via Garibaldi 7 C3 <br />
    16124 Genova</i>
    </p>
    <p>{t("posso avere ulteriori informazioni")} <a href={url_informazioni} target="_blank" rel="noreferrer">{url_informazioni}</a> {t("riguardo al funzionamento e posso chiamare il numero")} 0039 {telefono}</p>
    <p>{t("le scelte possono essere cambiate")}: {url_login}</p>
            </div>
            { secondTab !== "consensi" ?
              <Grid container spacing={1} alignItems="flex-start" className="blackColor">
                <Grid item xs={12}>
                    <MostButton label={t("Posso procedere alla compilazione")} onClick={goOn} id="Conferma" />
                </Grid>
              </Grid>
            : null }
        </Container>
        )
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect
    if(loading)
        return <Loading />

    const title = t("I tuoi consensi")
    return (
  <div className="app-container">
    <Header title={title} />
    <div className={"p_content-container p_contentWithTitleAndToolbar"} >
        <MobileTitle title={title} className="p_absolutemobiletitle" />
            <AppBar position="absolute">
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label={t("Informazioni")} id="tab-0" />
                    <Tab label={secondTab === "consensi" ? t("Consensi") : t("Domande preliminari")} id="tab-1" />
                    { secondTab === "consensi" && !insertingFirst ? 
                        <Tab label={t("Archivio")} id="tab-2" />
                    : null }
                </Tabs>
            </AppBar>
            <div id="startingDiv"/>
            { tab === "tab0" ?
                <Informazioni />
            : tab === "tab1" && secondTab === "consensi" ?
                <ConsensoForm userInfo={userInfo} />
            : tab === "tab1" ?
                <ConsensoPre changeTab={changeTab} viewConsensi={viewConsensi} />
            :
                <ConsensoStorico />
            }
    </div>
    <Footer />
  </div>
    )

};
