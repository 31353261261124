import React, { useCallback, useState, useEffect } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "../MyAxios";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import { Comunicazione } from './Comunicazione';
import { dmyhms_date_formatter, bool_rendercell, DataTable, dmy_date_formatter, } from '../components/DataTable';
import { Loading } from "../components/MostComponents";

export const StudioComunicazioni = (props) => {
    const { t } = useTranslation();
    const [id, setId] = useState(null)
    const [forceReload, setForceReload] = useState(0)
    const [mode, setMode] = useState("list")
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const [ready, setReady] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const rstatus_formatter = (params: ValueFormatterParams) => t(params.value)

    const columns = [
      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickSettings = () => {
            const id = params.id
            setId(id)
            for (let i in rows) {
                if(rows[i].id === id) {
                    setRow(rows[i])
                    break
                }
            }
            setMode("detail")
          }
          return (
            <div>
            <Tooltip title={t("Gestione comunicazione")}><IconButton className="gold" onClick={onClickSettings}><SettingsIcon /></IconButton></Tooltip>
            </div>
          )
        }
      },
      { field: 'subject', headerName: t('subject'), width: 250 },
      { field: 'description', headerName: t('description'), width: 250 },
      { field: 'sent', headerName: t('sent'), width: 120, renderCell: bool_rendercell },
      { field: 'recipients', headerName: t('Destinatari'), width: 150, },
      { field: 'sf36_request', headerName: 'sf36', width: 120, renderCell: bool_rendercell },
      { field: 'date2send', headerName: t('date2send'), width: 180, valueFormatter: dmyhms_date_formatter },
      { field: 'rstatus', headerName: t('Status'), width: 120, valueGetter: rstatus_formatter },
      { field: 'creation_date', headerName: t('creation_date'), width: 180, valueFormatter: dmy_date_formatter }
    ]

    useEffect(() => {
        function compareRows( a, b ) {
            if(!a.date2send && !b.date2send) {
                if(a.subject < b.subject)
                    return -1
                if(a.subject > b.subject)
                    return 1
                return 0
            }
            if(!a.date2send)
                return -1
            if(!b.date2send)
                return 1
            if(a.date2send < b.date2send)
                return 1
            if(a.date2send > b.date2send)
                return -1
            return 0
        }
        const formData = new FormData()
        formData.append('action', 'LIST')
        formData.append('as_role', 'GlobalAdmin')
        formData.append('study_id', props.studioId)
        const url = "/communications"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData
        ).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          log.debug("setRows",data.communications)
          data.communications.sort(compareRows)
          setRows(data.communications)
          setReady(true)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.studioId,forceReload])

    function showlist() {
        setForceReload(prevCount => prevCount + 1)
        setMode("list")
    }

    function nuovo() {
        if (props.row.status !== "Active" || props.row.rstatus !== "Active") {
            appAlert(t("Studio non attivo"))
            return
        }
        setId("__new__")
        setRow(null)
        setMode("detail")
    }

    if(mode === "detail") {
        if((id !== "__new__" && !row) )
            return <Loading />
        return (
            <Comunicazione row={row} id={id} studioId={props.studioId} studioRow={props.row} back={showlist} />
        )
    }

    // ? perche' necessario ??? 1 riga ma non visualizzata ???
    if(!ready)
        return <Loading />

    return (
        <DataTable columns={columns} rows={rows} density={"compact"} toolbar={true} tbButton={t("Nuova comunicazione")} tbButtonOnClick={nuovo} disableSelectionOnClick={true} />
    )
}
