import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import { Header } from './Header';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import { Footer } from './Footer';
import { PComunicazione } from './PComunicazione';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { dmy_hms, } from './Utils'
import MyAxios, { check_response } from "./MyAxios";
import { DataTable, } from './components/DataTable';
import { Loading } from "./components/MostComponents";
import { isPatient } from './UtilsInthem'

// sorgente usato anche per gli utenti
export const PComunicazioni = (props) => {
    const history = useHistory()
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // props.mode==="pcomunicazione"
    const id = props.match.params.id
    //log.debug("PComunicazioni id",id)


    function unread(id) {
        if(!userInfo.new_communications)
            return 0
        return userInfo.new_communications.includes(id)
    }

    const columns = [
      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
            const onClickSearch = () => {
              const id = params.id
              history.push("/pcomunicazione/"+id)
            }
            return (
              <Tooltip title={t("Visualizza comunicazione")}><IconButton className="gold" onClick={onClickSearch}><SearchIcon /></IconButton></Tooltip>
            )
          }
        },
      { field: 'date2send', headerName: t('Data'), width: 120, renderCell: (params: GridCellParams) => {
            const val = dmy_hms(new Date(params.value),2)
            if(unread(params.row.id))
                return ( <b> {val} </b> )
            else
                return ( <span> {val} </span> )
          }
        },
      { field: 'subject', headerName: t('subject'), minWidth: 300, flex: 1, renderCell: (params: GridCellParams) => {
            if(unread(params.row.id))
                return ( <b> {params.value} </b> )
            else
                return ( <span> {params.value} </span> )
          }
        },
      { field: 'study_id', headerName: t('Studio'), width: 200, renderCell: (params: GridCellParams) => {
            if(params.value) {
                if(unread(params.row.id))
                    return ( <b>{params.row.study.title}</b> )
                else
                    return ( <span>{params.row.study.title}</span> )
            }
            return null
          }
        },
      { field: 'recipients', headerName: ' ', width: 120, renderCell: (params: GridCellParams) => {
            if (isPatient(userInfo))
                // ? con return null si vede campo ?
                return ""
            return params.value
          }
        },
    ]

    useEffect(() => {
        function compareRows( a, b ) {
            if(a.date2send < b.date2send)
                return 1
            if(a.date2send > b.date2send)
                return -1
            return 0
        }
        const formData = new FormData()
        formData.append('action', 'LIST')
        const url = "/communications"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData
        ).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          if (!id)
            data.communications.sort(compareRows)
          setRows(data.communications)
          if (id) {
            let found = 0
            for (let i in data.communications) {
                if(data.communications[i].id === id) {
                    setRow(data.communications[i])
                    found = 1
                    break
                }
            }
            if(!found) {
                if (isPatient(userInfo))
                    history.push("/myconsent")
                else
                    history.push("/uhome")
            }
          }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,id,history,userInfo])

    if(props.mode==="pcomunicazione") {
        if(!row)
            return <Loading />
        return <PComunicazione row={row} id={id} />
    }

    return (
    <div className="app-container">
    <Header title={t("Le newsletter")} mobiletitle4patient />
    <div className="content-container no_v_padding">
        <DataTable columns={columns} rows={rows} density={"compact"} disableSelectionOnClick={true} />
    </div>
    <Footer />
  </div>
    );
};
