import React, { useState, useCallback } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useForm } from "react-hook-form";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from '@material-ui/core/Container';
import MyAxios, { check_response } from "../MyAxios";
import { getCenterAdmin, authorized, } from "../UtilsInthem";
import { MostSubmitButton, MostTextField, } from "../components/MostComponents";
import useStyles from "../components/useStyles";

export function CentroChangePasswd() {
    const { userInfo } = useGlobalHook('userStore');
    const centro = getCenterAdmin(userInfo)
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm();
    const classes = useStyles();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const [disabledButs, setDisabledButs] = useState(false)
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }

    const onSubmit = vals => {
        // cosi' logghiamo password
        //log.debug("onSubmit",vals)
        if (vals.newpassword !== vals.newpassword2) {
            appAlert(t("Le nuove password non coincidono"))
            return
        }
        let data= {
            newpassword: vals.newpassword
        }

        setDisabledButs(true)
        MyAxios.post("centerchangepassword", data)
        .then((response) => {
            const res = check_response(response)
            if (res.success) {
                appAlert2(t("Password modificata"),() => {
                    window.location.replace('/uhome')
                })
            } else {
                log.error("Error: "+res.error);
                appAlert(res.error);
                setDisabledButs(false)
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

  const redirect = authorized("CenterAdmin",userInfo)
  if(redirect)
    return redirect

  return (
  <div>
    <Header title={t("Cambio password e-CRF web services")+": "+t("Centro")+" "+centro} backMode="/uhome" />
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
          <MostTextField name="newpassword" required={true} label={t("Nuova Password" )}type="password" autoComplete="new-password" register={register} />
          <MostTextField name="newpassword2" required={true} label={t("Conferma Nuova Password" )}type="password" autoComplete="new-password" register={register} />
          <MostSubmitButton disabled={disabledButs} className="makeStyles-submit-4" label={t('Conferma')}/>
        </form>
      </div>
    </Container>
    <Footer />
  </div>
  );
}
