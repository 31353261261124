import React from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MobileTitle } from './MobileTitle';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized, } from '../UtilsInthem'
import { getLang } from '../i18n'
import Grid from "@material-ui/core/Grid";
import { MostButton2, MostButton, } from "../components/MostComponents";
import { useHistory } from "react-router-dom";

export const MyConsent = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const getTitle = (obj) => {
        const lang = getLang(i18n.language)
        if(obj['trans_'+lang])
            return (obj['trans_'+lang])
        if(obj['trans_en'])
            return (obj['trans_en'])
        return (obj['trans_it'])
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect
    
    let communicationsLabel = ""
    if (userInfo.new_communications.length > 1)
        communicationsLabel = userInfo.new_communications.length + " " + t("newsletter non lette")
    else
        communicationsLabel = "1 " + t("newsletter non letta")

    const title = t("MyConsent")
    return (
  <div className="app-container">
    <Header homepage title={title} />
    <div className="p_content-container MyConsent" >
    <MobileTitle title={title}/>
    <div style={{height:30}} />
    <Container component="main" maxWidth="md">
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
        {0 || userInfo.c_db === null ?
            <Grid item xs={12} className="red big">
                Il paziente non ha dato l'autorizzazione
                all'iscrizione al sistema
            </Grid>
        : null}
        {0 || userInfo.c_db === null || userInfo.c_db_p === null ?
            <React.Fragment>
            <Grid item xs={12} className="important big">
                <p className="MyConsentWelcome">
                    {t("Benvenuto")} {userInfo.name} {userInfo.surname}
                </p>
                {t("attivare necessario consensi")}
                <br />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
                <MostButton className="b_loginok" onClick={() => history.push("/consenso")} label={t("inserisci i tuoi consensi")} id="consenso"/>
            </Grid>
            <Grid item xs={12}>
                <div className="MyConsentSpace" />
                {t("Successivamente potrai utilizzare il tuo ambiente")}:
            </Grid>
            </React.Fragment>
        : userInfo.studi_attesa.length?
            userInfo.studi_attesa.map((studio,studioIndex)=>(
                <React.Fragment key={"S"+studioIndex}>
                    <Grid item xs={12} className="important big">
                    {t("Sei stato invitato a partecipare a un nuovo studio")}: <br />
                    <big>{t("Studio")} {getTitle(studio.clinical_study_title)}</big> <br />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={8}>
                        <MostButton className="b_loginok" label={t("Informati e decidi se partecipare")} onClick={() => history.push("/pstudio/"+studio.clinical_study_id)} id={"s"+studioIndex}/>
                        <div className="MyConsentSpace" />
                    </Grid>
                </React.Fragment>
            ))
        : null }
        <Grid item xs={4}>{t("I tuoi dati")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/pdati")} label={t("Visualizza")} id="pdati"/>
        </Grid>
        <Grid item xs={4}>{t("I tuoi consensi")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/consenso")} label={t("Visualizza")} id="consenso"/>
        </Grid>
        <Grid item xs={4}>{t("I tuoi questionari")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/sf36")} label={t("Visualizza")} id="viewSf36" />
        </Grid>
        <Grid item xs={4}>{t("Gli studi clinici")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/pstudi")} label={t("Visualizza")} id="pstudi" />
        </Grid>
        <Grid item xs={4}>{t("Le newsletter")}</Grid>
        <Grid item xs={8}>
            {0 || userInfo.new_communications.length?
                <MostButton fullWidth className="b_loginok" onClick={() => history.push("/pcomunicazioni")} label={communicationsLabel} />
            :
                <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/pcomunicazioni")} label={t("Visualizza")} />
            }
        </Grid>
        <Grid item xs={4}>{t("Nuovo questionario")}</Grid>
        <Grid item xs={8}>
            <MostButton2 fullWidth className="b_loginok" onClick={() => history.push("/sf36_new")} label={t("Compila")} id="sf36_new" />
        </Grid>
        </Grid>
    </Container>
    <div className="centroBox">
        {t("Il tuo centro di riferimento")}:
        <div> {userInfo.center.name} </div>
        <div> {userInfo.center.city} </div>
        <div> {userInfo.center.address} </div>
    </div>

{/*
vedi 2.3.10 capitolato MyConsent <br />
vedi 2.3.11 capitolato Invio Promemoria <br/>
*/}
    </div>
    <Footer />
  </div>
    );
};
