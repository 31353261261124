import React, { useCallback, useMemo, useState, } from "react";
import log from 'loglevel';
import MyAxios, {check_response} from "../MyAxios";
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { getCenterAdmin } from '../UtilsInthem'
import { MostSubmitButton, MostButton2, MostTextField, MostAutocomplete, } from "../components/MostComponents";

export const EcrfNuovoPaziente = (props) => {
    const { t } = useTranslation();
    const [disabledButs, setDisabledButs] = useState(false)
    const { errors, register, control, handleSubmit, } = useForm();
    const { userInfo } = useGlobalHook('userStore');
    const centro = getCenterAdmin(userInfo)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }

    const options_login_mode = useMemo(() => [
        {value: "Email", label: t("Indirizzo e-mail")},
        {value: "Mobile", label: t("Numero di cellulare")},
    ], [t])

    function back() {
        props.setMode(null)
    }

    const onInsert = (vals) => {
        log.debug("onInsert", vals)
        const mobileVal = vals.mobile.trim()
        const login_mode = vals.login_mode.value
        const email = vals.email.trim().toLowerCase()
        if(login_mode === "Email" && email === "") {
            appAlert(t("Campo email obbligatorio"))
            return
        }
        if(login_mode === "Mobile" && mobileVal === "") {
            appAlert(t("Campo cellulare obbligatorio"))
            return
        }
        setDisabledButs(true)
        const jdata = {
            action: 'ADD_PATIENT',
            center_code: centro,
            center_id: vals.center_id,
            surname: vals.surname.toUpperCase(),
            name: vals.name.toUpperCase(),
            login_mode: login_mode,
            mobile: mobileVal,
            email: email,
            c_db: true,
        }
        MyAxios.post("/e_crf", jdata)
        .then((response) => {
            setDisabledButs(false)
            const res = check_response(response)
            if (!res.success) {
                log.error("Error: "+res.error);
                appAlert(res.error);
                return
            }
            appAlert2(t("Paziente registrato"),() => {
                props.reloadPatients()
                back()
            })
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    return (
        <div className="app-container">
            <Header title={"e-CRF " + centro + " / "+t("Nuovo paziente")} />
            <div className="content-container contentWithToolbar">
                <form onSubmit={handleSubmit(onInsert)} noValidate>
                <div className="formToolbar">
                    <MostSubmitButton id="insert" fullWidth={false} disabled={disabledButs} label={t("Inserisci")} />
                    <MostButton2 id="back" fullWidth={false} disabled={disabledButs} className="margin2416" onClick={back} label={t("Indietro")} />
                </div>
                <Container component="main" maxWidth="sm" className="blackColor">
                    <MostTextField name="center_id" label={t("Codice univoco")} required={true} register={register({ required: true })} errors={errors} />
                    <MostTextField name="surname" label={t("Cognome")} required={true} register={register({ required: true })} errors={errors} />
                    <MostTextField name="name" label={t("Nome")} required={true} register={register({ required: true })} errors={errors} />
                    <MostAutocomplete control={control} name="login_mode" options={options_login_mode} label={t("Campo di autenticazione")+" *"} required={true} rules={{ required: true }}  errors={errors} />
                    <MostTextField name="email" register={register} label={t("Email")} />
                    <MostTextField name="mobile" register={register} label={t("Cellulare")} />
                    <br/><br/> <b>{t("Nota")}:</b>{t("paziente deve avere dato consenso")} 
                </Container>
            </form>
            </div>
            <Footer />
        </div>
    )
}
