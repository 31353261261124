import React from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useHistory } from "react-router-dom";
import { MostButton, } from "../components/MostComponents";
import { hasRole, authorized } from '../UtilsInthem'
import Grid from "@material-ui/core/Grid";

export const UHome = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const history = useHistory();
    const globalAdminRole = hasRole("GlobalAdmin",userInfo)
    const userAdminRole = hasRole("UserAdmin",userInfo)
    const dataManagerRole = hasRole("DataManager",userInfo)
    const centerAdminRole = hasRole("CenterAdmin",userInfo)

    const redirect = authorized("user",userInfo)
    if(redirect)
        return redirect

    return (
  <div className="app-container">
    <Header />
    <div className="content-container">
    <Container component="main" maxWidth="md">
        <h1>{t("Benvenuto")} {userInfo.name} {userInfo.surname}</h1>
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
        { globalAdminRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("Global Administrator")}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/ga_studiclinici")} label={t("Gestione studi clinici")} id="ga_studiclinici" />
                    <MostButton className="b_loginok" onClick={() => history.push("/ga_filtri")} label={t("Gestione filtri")} id="filtri1" />
                    <MostButton className="b_loginok" onClick={() => history.push("/comunicazioni")} label={t("Comunicazioni")} id="comunicazioni" />
                    <MostButton className="b_loginok" onClick={() => history.push("/questionari")} label={t("Questionari")} id="questionari" />
                    <MostButton className="b_loginok" onClick={() => history.push("/globalconfig")} label={t("Configurazione globale")} id="globalconfig" />
                    <MostButton className="b_loginok" onClick={() => history.push("/log")} label={t("Log")} id="log" />

            </Grid>
            </React.Fragment>
        : null
        }
        { userAdminRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("User Administrator")}
                {userAdminRole.areaid ?
                    <div>{t("Area")} {userAdminRole.areaid}</div>
                : null}
                {userAdminRole.center ?
                    <div>{t("Centro")} {userAdminRole.center}</div>
                : null}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/centri")} label={t("Gestione centri")} id="centri" />
                    <MostButton className="b_loginok" onClick={() => history.push("/utenti")} label={t("Gestione utenti")} id="utenti" />
            </Grid>
            </React.Fragment>
        : null
        }
        {dataManagerRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("Data Manager")}
                {dataManagerRole.areaid ? 
                        <div>{t("Area")} {dataManagerRole.areaid}</div>
                : null}
                {dataManagerRole.center ? 
                        <div>{t("Area")} {dataManagerRole.center}</div>
                : null}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/dm_filtri")} label={t("Gestione filtri")} id="filtri2" />
                    <MostButton className="b_loginok" onClick={() => history.push("/dm_studiclinici")} label={t("Studi clinici")} id="dm_studiclinici" />
{/*
vedi 2.3.12 capitolato: Scambi documenti e note (GA e DM)
ma social forum era poi stato scartato
                    <MostButton disabled className="b_loginok" label={t("Scambi documenti e note")} />
*/}
                    <MostButton disabled className="b_loginok" label={t("Analisi Estrazioni Report")} />
            </Grid>
            </React.Fragment>
        : null
        }
        { centerAdminRole ?
            <React.Fragment>
            <Grid item xs={6}>
                {t("Center Administrator")} <br />
                {t("Centro")} {centerAdminRole.center}<br />
                {t("Area")} {centerAdminRole.areaid}
            </Grid>
            <Grid item xs={6}>
                    <MostButton className="b_loginok" label={t("Sincronizzazione pazienti e dati")} onClick={() => history.push("/pazienti-dati")} id="pazienti-dati" />

                    <MostButton className="b_loginok" onClick={() => history.push("/ca_studiclinici")} label={t("Aggiornamento studi clinici")} id="ca_studiclinici" />
                    <MostButton className="b_loginok" onClick={() => history.push("/ca_filtri")} label={t("Gestione filtri")} id="filtri3" />

                    <MostButton className="b_loginok" label={t("Mini e-CRF")} onClick={() => history.push("/ecrf")} id="ecrf" />

                    <MostButton disabled className="b_loginok" label={t("Analisi Estrazioni Report")} />

                    <MostButton className="b_loginok" label={t("Download Diagnosi e Tipi chelanti")} onClick={() => history.push("/downloaddiagnositipichelanti")} id="downloaddiagnositipichelanti" />

                    <MostButton className="b_loginok" label={t("Cambio password e-CRF web services")} onClick={() => history.push("/centrochangepasswd")} id="centrochangepasswd" />
            </Grid>
            </React.Fragment>
        : null
        }
            <Grid item xs={6}>
                {t("Funzioni per tutti gli utenti")}
            </Grid>
            <Grid item xs={6}>
                <MostButton className="b_loginok" onClick={() => history.push("/pcomunicazioni")} label={t("Newsletter")} id="newsletter" />
            </Grid>
        </Grid>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
