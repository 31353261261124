import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MobileTitle } from './MobileTitle';
import { DataTable, dmy_date_formatter, } from '../components/DataTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import { string_dmy_hms, showDoc } from '../Utils'
import MyAxios, { check_response, } from "../MyAxios";

export const Sf36 = (props) => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    const [rows, setRows] = useState([])
    const [forceReload, setForceReload] = useState(0)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    const columns = [
      { field: 'bottoni1', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickView = () => {
            const s3_id = params.row.s3_id
            showDoc(s3_id)
          }
          return (
            <Tooltip title={t("Visualizza")}><IconButton className="gold" onClick={onClickView}><SearchIcon /></IconButton></Tooltip>
          )
        }
      },
      { field: 'insert_time', type: 'dateTime', headerName: t('Data'), width: 130, valueFormatter: dmy_date_formatter },
      { field: 'bottoni2', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickDelete = () => {
            const id = params.id
            delete_sf3(id,params.row.insert_time)
          }
          return (
            <Tooltip title={t("Rimuovi")}><IconButton className="gold" onClick={onClickDelete}><DeleteIcon /></IconButton></Tooltip>
          )
        }
      },
    ]

    useEffect(() => {
        const jdata = {
        }
        MyAxios.post("/patient/GetSF36List", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(data.sf36)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,forceReload,])

    function delete_sf3(id,data) {
        const text = t("Cancellare il questionario del")+" "+string_dmy_hms(data,2)+" ?"
        appConfirm(text,() => {
            const jdata = {
                id: id,
            }
            MyAxios.post("/patient/DeleteSF36", jdata)
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect

    const title = t("I tuoi questionari")
    return (
  <div className="app-container">
    <Header title={title} />
    <div className="p_content-container displayFlex flexflowColumn">
        <MobileTitle title={title} />
        <div className="whiteback flex1">
            <DataTable columns={columns} rows={rows} density={"compact"} disableSelectionOnClick={true} />
        </div>
    </div>
    <Footer />
  </div>
    );
};
