import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import { DataTable, dmyhms_date_formatter, } from '../components/DataTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import { showDoc } from '../Utils'
import { Loading, } from "../components/MostComponents";
import MyAxios, { check_response, } from "../MyAxios";

 export const ConsensoStorico = (props) => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const columns = [
      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickView = () => {
            const s3_id = params.row.s3_id
            showDoc(s3_id)
          }
          return (
            <div>
            <Tooltip title={t("Visualizza")}><IconButton className="gold" onClick={onClickView}><SearchIcon /></IconButton></Tooltip>
            </div>
          )
        }
      },
      { field: 'insert_time', type: 'dateTime', headerName: t('Data'), width: 160, valueFormatter: dmyhms_date_formatter },
    ]

    useEffect(() => {
        const jdata = {
        }
        MyAxios.post("/patient/GetConsentList", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(data.consents)
          setLoading(false)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,])

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect
    if(loading)
        return <Loading />

    return (
            <DataTable columns={columns} rows={rows} density={"compact"} disableSelectionOnClick={true} />
    )
 }

