import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import { useHistory } from "react-router-dom";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Questionario } from './Questionario';
import { DataTable, } from '../components/DataTable';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import MyAxios, { check_response } from "../MyAxios";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Loading, } from "../components/MostComponents";

export const Questionari = (props) => {
    const history = useHistory()
    const { userInfo } = useGlobalHook('userStore');
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const { t } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // props.mode==="questionario"
    const qId = props.match.params.qId

    const columns = [
      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
            const view = () => {
                const id = params.id
                history.push("/questionario/"+id)
            }
            return (
                <Tooltip title={t("Visualizza")}><IconButton className="gold" onClick={view}><SearchIcon /></IconButton></Tooltip>
            )
         }
      },
      { field: 'id', headerName: t('Codice'), width: 150 },
      { field: 'name', headerName: t('Nome'), width: 250 },
      { field: 'description', headerName: t('Descrizione'), width: 250 },
    ];

    useEffect(() => {
        const jdata = {
            action: "LIST",
        }
        MyAxios.post("/sf36", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(data.rows)
          if(qId && qId !== "__new__") {
            let found = 0
            for (let i in data.rows) {
                if(data.rows[i].id === qId) {
                    setRow(data.rows[i])
                    found = 1
                    break
                }
            }
            if(!found)
                history.push("/questionari")
          }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,history,qId,])

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    if(props.mode==="questionario") {
        if(qId !== "__new__" && !row)
            return <Loading />
        return (
            <Questionario row={row} qId={qId} />
        )
    }

    return (
        <div className="app-container">
        <Header title={t("Gestione questionari")} />
        <div className="content-container">
        <DataTable columns={columns} rows={rows} density={"compact"} toolbar={true} tbButton={t("Nuovo questionario")} tbButtonOnClick={() => history.push("/questionario/__new__")} disableSelectionOnClick={true} />
        </div>
        <Footer />
        </div>
    )
}
