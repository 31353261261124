import React, { useCallback, useState, useEffect } from "react"
import log from 'loglevel';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getLang, getLocale } from "../i18n";
import { getCurrentTimezone, get_cookie, delete_cookie } from "../Utils";
import { useForm } from "react-hook-form";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "../MyAxios";
import { Loading, MostButton2, MostButton, MostTextField, } from "../components/MostComponents";
import useStyles from "../components/useStyles";
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

export function SignInPatient(props) {
const history = useHistory();
const { t, i18n } = useTranslation();
const { register } = useForm();
const classes = useStyles();
const [disabledButs, setDisabledButs] = useState(false)
const [code, setCode] = useState("")
const [codeSent, setCodeSent] = useState(false)
const [defaultPassword, setDefaultPassword] = useState(null)
const [checkingExpiredPassword, setCheckingExpiredPassword] = useState(true)
const { setAlert1, setContent } = useGlobalHook('alertStore');
const { setUserInfo } = useGlobalHook('userStore');
const appAlert = useCallback((text) => {
  setContent(text);
  setAlert1(true);
}, [setContent,setAlert1])
const query = new URLSearchParams(window.location.search)
const u = query.get('u')
const p = query.get('p')

useEffect(() => {
  // nota: solo se password e' scaduta usiamo la password contenuta nella url
  if(u && p) {
    const data = {
        username: u
    }
    MyAxios.post("checkexpiredpasswordpatient", data)
    .then((response) => {
        const res = check_response(response)
        if (res.success && res.expired) {
            setDefaultPassword(p)
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
    .then(() => {
        setCheckingExpiredPassword(false)
    })
  } else
    setCheckingExpiredPassword(false)
}, [u,p,appAlert])

const getUsername = () => {
    const form = document.getElementById("formid");
    const username = form.username.value.trim()
    if (username === "") {
        appAlert(t("Inserisci il tuo indirizzo e-mail o il tuo numero di cellulare"))
        return null
    }
    if (username.indexOf('@') !== -1) {
        // email
        if (username.length < 6) {
            appAlert(t("Indirizzo e-mail non corretto"))
            return null
        }
    } else {
        // numero di cellulare
        if(username.length < 7 || (username.substring(0,1) !== "3" && username.substring(0,1) !== "+")) {
            appAlert(t("Numero di cellulare non corretto"))
            return null
        }
    }
    return username
}

const chiediCodice = () => {
    const username = getUsername()
    if (!username)
        return
    const form = document.getElementById("formid");
    const password = form.password.value
    if(password === "") {
        appAlert(t("Inserisci la password"))
        return
    }
    const lang = getLang(i18n.language)
    const locale = getLocale(i18n.language)
    const timezone = getCurrentTimezone()
    const data = {
        username: username,
        password: password,
        lang: lang,
        locale: locale,
        timezone: timezone,
    }
    setDisabledButs(true)
    MyAxios.post("otp", data)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            let msg = ''
            if(username.indexOf('@') !== -1)
                msg = t("Ti è stata inviata una mail contenente un codice")
            else
                msg = t("Ti è stato inviato un SMS contenente un codice")
            msg += '<br>'+t("Inserisci il codice nella casella sottostante")
            appAlert(msg)
            setDisabledButs(false)
            setCodeSent(true)
        } else {
            log.debug("Error: login: "+res.error);
            appAlert(t("Errore")+": "+res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
}

const submit = () => {
    const username = getUsername()
    if (!username)
        return
    const form = document.getElementById("formid");
    const otp = form.otp.value.trim()
    if (otp === "") {
        appAlert(t("Inserisci il codice che ti è stato inviato"))
        return null
    }
    if (otp.length !== 6) {
        appAlert(t("Codice non corretto"))
        return null
    }
    const lang = getLang(i18n.language)
    const locale = getLocale(i18n.language)
    const timezone = getCurrentTimezone()
    let data= {
        username: username,
        otp: otp,
        lang: lang,
        locale: locale,
        timezone: timezone,
    }
    setDisabledButs(true)
    MyAxios.post("otplogin", data)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            props.resetIdleTimeout()
            setUserInfo(res.user)
            if(res.user.passwordExpired)
                history.push('/changepasswd');
            else {
                const pathnameOnUnauthAccess = get_cookie("pathnameOnUnauthAccess")
                if(pathnameOnUnauthAccess !== "") {
                    delete_cookie("pathnameOnUnauthAccess","/")
                    history.push(pathnameOnUnauthAccess)
                }
                else
                    history.push('/myconsent');
            }
        } else {
            log.debug("Error: login: "+res.error);
            appAlert(t("Accesso fallito")+": "+res.error);
            setDisabledButs(false)
            setCode("")
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
        setCode("")
    })
}

const txtOnChange = (e) => {
    setCode(e.target.value)
}

  if (checkingExpiredPassword)
    return <Loading />

  //log.debug("defaultPassword",defaultPassword)
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {/*
        <img src={logo} className="xxxxApp-logo" alt="logo" />
        */}
        <Typography component="h1" variant="h5">
          DB-INTHEM
        </Typography>
        <p>{t("Accesso Pazienti")}</p>
        <form id="formid" className={classes.form} >
          <MostTextField name="username" required={true} label={t("Email or Phone Number")} autoComplete="current-username" type="text" autoFocus={true} register={register} defaultValue={u} />
          <MostTextField name="password" type="password" required={true} label="Password" autoComplete="current-password"  register={register} defaultValue={defaultPassword} />
          {/*
          <MostCheckbox register={register} name="remember" default={false} label={t("SignIn.Remember me")} />
          */}
          { codeSent ?
            <MostButton2 fullWidth={true} disabled={disabledButs} label={t("SignIn.Richiedi nuovamente Codice")} onClick={chiediCodice} className="margin2416 width100"/>
          :
            <MostButton id="chiedi-codice" disabled={disabledButs} label={t("SignIn.Richiedi Codice")} onClick={chiediCodice} />
          }
          <MostTextField name="otp" value={code} onChange={txtOnChange} disabled={!codeSent} label={t("Codice ricevuto")+" *"} autoComplete="current-username" type="text" autoFocus={true} register={register} />
          <MostButton id="sign-in" disabled={disabledButs || !codeSent} label={t('SignIn.Sign In')} onClick={submit} />
        <Grid container>
            <Grid item xs>
              <Link to="/forgot_password_patient" variant="body2">
                {t('SignIn.Forgot password?')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
