import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Comunicazione } from './Comunicazione';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { dmyhms_date_formatter, bool_rendercell, DataTable, dmy_date_formatter, } from '../components/DataTable';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import SettingsIcon from '@material-ui/icons/Settings';
import MyAxios, { get_document, check_response } from "../MyAxios";
import { Loading } from "../components/MostComponents";

export const Comunicazioni = (props) => {
    const history = useHistory()
    const { userInfo } = useGlobalHook('userStore');
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const { t } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const [forceReload, setForceReload] = useState(0)
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
    function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
    }

    // props.mode==="comunicazione"
    const id = props.match.params.id
    //log.debug("Comunicazioni id",id)

    const rstatus_formatter = (params: ValueFormatterParams) => t(params.value)

    const columns = [

      { field: 'bottoni1', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
            const onClickSettings = () => {
              const id = params.id
              history.push("/comunicazione/"+id)
            }
            return (
              <Tooltip title={t("Gestione comunicazione")}><IconButton className="gold" onClick={onClickSettings}><SettingsIcon /></IconButton></Tooltip>
            )
          }
      },
      { field: 'subject', headerName: t('subject'), width: 250 },
      { field: 's3_id', headerName: 'pdf', width: 100, renderCell: (params: GridCellParams) => {
          if(!params.row.s3_id)
            return null
          return (
            <Tooltip title={t("Download")}>
              <IconButton
                onClick={() => download(params.row.s3_id)}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
           )},
          } ,
      { field: 'description', headerName: t('description'), width: 250 },
      { field: 'video_url', headerName: t('video_url'), width: 250 , renderCell: (params: GridCellParams) => {
          return (
          <a href={params.row.video_url} target="_blank" rel="noreferrer" >{params.row.video_url}</a>
         )},
      },
      { field: 'sent', headerName: t('sent'), width: 120, renderCell: bool_rendercell },
      { field: 'to_everybody', headerName: t('to_everybody'), width: 120, renderCell: bool_rendercell },
      { field: 'date2send', headerName: t('date2send'), width: 180, valueFormatter: dmyhms_date_formatter },
      { field: 'rstatus', headerName: t('Status'), width: 120, valueGetter: rstatus_formatter },
      { field: 'creation_date', headerName: t('creation_date'), width: 180, valueFormatter: dmy_date_formatter },
      { field: 'bottoni2', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
            const onClickDelete = () => {
              const id = params.id
              delete_comunicazione(id,params.row)
            }
            if(params.row.sent)
                return null
            return (
              <Tooltip title={t("Rimuovi")}><IconButton className="gold" onClick={onClickDelete}><DeleteIcon /></IconButton></Tooltip>
            )
          }
      },
    ];

    useEffect(() => {
        function compareRows( a, b ) {
            if(a.sent && !b.sent)
                return 1
            if(!a.sent && b.sent)
                return -1
            if(a.date2send && !b.date2send)
                return 1
            if(!a.date2send && b.date2send)
                return -1
            if(a.date2send < b.date2send)
                return 1
            if(a.date2send > b.date2send)
                return -1
            if(a.creation_date < b.creation_date)
                return -1
            if(a.creation_date > b.creation_date)
                return 1
            return 0
        }
        const formData = new FormData()
        formData.append('as_role', 'GlobalAdmin')
        formData.append('action', 'LIST')
        log.debug("/communications", [...formData.entries()])
        MyAxios.post("/communications", formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          if (!id)
            data.communications.sort(compareRows) 
          setRows(data.communications)
          if (id && id !== "__new__") {
            let found = 0
            for (let i in data.communications) {
                if(data.communications[i].id === id) {
                    setRow(data.communications[i])
                    found = 1
                    break
                }
            }
            if(!found)
                history.push("/uhome")
          }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,id,history,forceReload,])

    function delete_comunicazione(id,row) {
        const text = t("Cancellare la comunicazione")+" '"+row.subject+"' ?"
        appConfirm(text,() => {
            const formData = new FormData()
            formData.append('as_role', 'GlobalAdmin')
            formData.append('action', 'DELETE')
            formData.append('id', id)
            log.debug("/communications", [...formData.entries()])
            MyAxios.post("/communications", formData)
            .then((response) => {
              const data = check_response(response);
              if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
              }
              setForceReload(prevCount => prevCount + 1)
            })
            .catch(function (error) {
                log.error(error);
                appAlert(error.message?error.message:JSON.stringify(error));
            })
        })
    }

    const nuovo = (el) => {
        history.push("/comunicazione/__new__")
    }

    const download = (id) => {
        get_document(id,appAlert)
    }

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    if(props.mode==="comunicazione") {
        if((id !== "__new__" && !row) )
            return <Loading />

        return (
            <Comunicazione row={row} id={id} />
        )
    }

    return (
        <div className="app-container">
        <Header title={t("Gestione Comunicazioni")} />
        <div className="content-container">
        <DataTable columns={columns} rows={rows} density={"compact"} toolbar={true} tbButton={t("Nuova comunicazione")} tbButtonOnClick={nuovo} disableSelectionOnClick={true} />
{/*
capitolato:

Nel caso in cui una variazione nelle leggi o nei regolamenti sulla privacy oppure una estensione dei dati trattati da DB-INTHEM richiedano un ulteriore consenso, il GlobalAdmin deve poter aggiungere una voce alla tabella dei tipi consensi da richiedere e creare una nuova comunicazione (eventualmente accompagnata da un video messaggio di spiegazione ed invito) che, tramite l'applicazione verrà invierà a tutti i Pazienti sotto forma di SMS/email con link diretto per fornire il nuovo consenso desiderato (i consensi già forniti rimangono comunque operativi e intoccati).
- a cosa serve un nuovo consenso non gestito dal codice?

Per ogni Studio Clinico il GlobalAdmin può creare una o più Comunicazioni, ciascuna composta da un breve messaggio testuale eventualmente accompagnato da un videomessaggio (su piattaforma esterna). Il messaggio è inviato a quei pazienti elegibili per lo studio clinico che non avevano dato il loro consenso al trattamento dei dati, il messaggio sarà informativo sul tipo di studio e chiederà al paziente di cambiare il suo consenso al trattamento dei dati in caso questo accetti a partecipare. Il messaggio deve contenere un riferimento al centro presso cui gli è stato proposto di essere inserito su DB-INTHEM.
- anche a chi ha detto no al consenso? adesso solo a chi e' in stato attesa di consenso
*/}
        </div>
        <Footer />
        </div>
    )
}
