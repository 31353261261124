import React, { useState, } from "react";
import log from 'loglevel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormLabel from "@material-ui/core/FormLabel";
import Switch from '@material-ui/core/Switch';
import { MostTable } from "../components/MostTable";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { MostCheckbox, MostTextField, } from "../components/MostComponents";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const TabCampiCondizioni = (props) => {
    const { t } = useTranslation();
    let initialTab = 0
    const [tabValue, setTabValue] = useState(initialTab)
    const [tab, setTab] = useState("tab"+initialTab)

    const columns = [
        {
            Header: t("Campo"),
            accessor: "campo",
        },
        {
            Header: t("Operatore"),
            accessor: "operatore",
        },
        {
            Header: t("Valore"),
            accessor: "valore",
        },
        {
            Header: "",
            accessor: "ID",
            Cell: ({ cell: { value }, row: { original } }) => {
              return (
                <Tooltip title={t("Rimuovi")}>
                  <IconButton
                    onClick={() => deleteCondizione(original.tipo,value)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
             )},
        },
    ]

    function deleteCondizione(tipo,id) {
        log.debug("deleteCondizione",tipo,id)
        if(tipo === "personal") {
            const v = []
            for (let i in props.condizioniAnagrafiche) {
                const el = props.condizioniAnagrafiche[i]
                if(el.ID !== id)
                    v.push(el)
            }
            props.setCondizioniAnagrafiche(v)
        } else {
            // medical
            const v = []
            for (let i in props.condizioniMediche) {
                const el = props.condizioniMediche[i]
                if(el.ID !== id)
                    v.push(el)
            }
            props.setCondizioniMediche(v)
        }
    }

    const sql_conditionOnChange = (e) => {
        props.setSql_condition(e.target.value)
    }

    const sqlFromCondizioni = () => {
        if(props.sql_condition !== "") {
            props.appAlert(t("Rimuovi il contenuto della casella di testo")+" "+t("Condizione SQL"))
            return
        }
        let cond = ""
        for (let i in props.condizioniAnagrafiche) {
            const el = props.condizioniAnagrafiche[i]
            if (cond !== "")
                cond += " and "
            let campo
            if (el.campo==="center_code")
                // presente in entrambe le tabelle (patient medical_data)
                campo = "patient.center_code"
            else
                campo = el.campo
            cond += campo + " " + 
                    el.operatore + " "
            if (el.valore)
                cond += el.valore
        }
        for (let i in props.condizioniMediche) {
            const el = props.condizioniMediche[i]
            if (cond !== "")
                cond += " and "
            cond += el.campo + " " + 
                    el.operatore + " "
            if (el.valore)
                cond += el.valore
        }
        props.setSql_condition(cond)
    }

    const handleChangeCheckedSimple = () => {
        props.setCheckedSimple((prev) => !prev);
        // quando si switcha azzeriamo sempre condizione sql
        // (se si lavora con interfaccia sql_condition deve essere vuota
        // altrimenti ha precedenza)
        props.setSql_condition("")
    }

    function nuovaCondizioneAnagrafica() {
        props.resetAndOpenConditionDialog("personal")
    }
    function nuovaCondizioneMedica() {
        props.resetAndOpenConditionDialog("medical")
    }

    const changeTab = (tabNumber) => {
        setTabValue(tabNumber)
        setTab("tab"+tabNumber)
    }
    const handleTabChange = (e,tabNumber) => {
        changeTab(tabNumber)
    }

    if (props.filterId === "__new__" || props.modeFiltroForm === "updating") {
        //log.debug("TabCampiCondizioni new or updating")
        return (
          <React.Fragment>
            <FormLabel> {t("Campi da esportare")} </FormLabel>
            { props.filterId === "__new__" ? // gestiamo solo in creazione nuovo filtro
                <React.Fragment>
                    <br />
                    <MostCheckbox label={t("Tutti i campi anagrafici e medici")} checked={props.allPersonalAndMedical} onChange={props.allPersonalAndMedicalOnChange} />
                </React.Fragment>
            : null }
            <Box border={1} borderRadius={4} className={"box "+(props.allPersonalAndMedical?"hidden":"visible")} >
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    {t("Campi anagrafici")}
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container spacing={1} alignItems="center" className="blackColor">
                    <Grid item xs={12}>
                    <MostCheckbox label={t("Tutti i campi")} checked={props.allPersonal} onChange={props.allPersonalOnChange} />
                    </Grid>
                    { props.personal_fields.map((item, index) => (
                                <Grid item className={props.allPersonal ? "hidden":"visible"} xs={6} sm={4} md={3} key={"gr1_"+index}>
                                <MostCheckbox register={props.register} key={"xpf"+index} name={"chk_personal_"+item} label={item} defaultChecked={props.defaultChecked[item]} />
                                </Grid>
                                )) }
                    </Grid>
                    </AccordionDetails>
                </Accordion>
                { props.medical_field_category.map((item, index) => (
                    <Accordion key={"xfc"+index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        { Object.keys(item)[0] }
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={1} alignItems="center" className="blackColor">
                        <Grid item xs={12}>
                        <MostCheckbox label={t("Tutti i campi")} checked={props.allMedical[index]} onChange={(e,val) => props.allMedicalOnChange(val,index)} />
                        </Grid>
                        { item[Object.keys(item)[0]].map((item1, index1) => (
                                    <Grid item className={props.allMedical[index] ? "hidden":"visible"} xs={12} sm={6} md={4} key={"gr2_"+index+"_"+index1}>
                                    <MostCheckbox register={props.register} key={"xpm"+index+"_"+index1} name={"chk_medical_"+Object.keys(item)[0]+"_"+item1} label={item1} defaultChecked={props.defaultChecked[item1]} />
                                    </Grid>
                                    )) }
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                )) }
            </Box>
            <br />
            <FormLabel>{t("Condizioni di ricerca")+" "}</FormLabel>
            <FormControlLabel
                control={<Switch checked={props.checkedSimple} onChange={handleChangeCheckedSimple} />}
                label={t("Interfaccia guidata")}
            />
            <div className={props.checkedSimple?"visible":"hidden"}>
                <Box border={1} borderRadius={4} className="box" >
                <div className="blackColor divInBox">
                    <FormLabel>{t("Campi anagrafici")}</FormLabel>
                    <Toolbar className="rowreverse">
                    <Button variant="contained" onClick={nuovaCondizioneAnagrafica} className="submitButton" > {t("Nuova condizione")} </Button>
                    </Toolbar>
                    { props.condizioniAnagrafiche.length ?
                    <MostTable className="grayTable" columns={columns} data={props.condizioniAnagrafiche} />
                :
                    <div className="boxForm tcenter">
                        {t("Nessuna condizione")}
                    </div>
                }
                </div>
                </Box>
                <Box border={1} borderRadius={4} className="box" >
                <div className="blackColor divInBox">
                    <FormLabel>{t("Campi medici")}</FormLabel>
                    <Toolbar className="rowreverse">
                        <Button variant="contained" onClick={nuovaCondizioneMedica} className="submitButton" > {t("Nuova condizione")} </Button>
                    </Toolbar>
                    { props.condizioniMediche.length ?
                        <MostTable className="grayTable" columns={columns} data={props.condizioniMediche} />
                    :
                        <div className="boxForm tcenter">
                            {t("Nessuna condizione")}
                        </div>
                    }
                </div>
                </Box>
            </div>
            <div className={!props.checkedSimple?"visible":"hidden"}>
                <Box border={1} borderRadius={4} className="box" >
                <div className="blackColor divInBox">
                    <Button variant="contained" onClick={sqlFromCondizioni} className="submitButton" > {t("Riporta le condizioni già inserite")} </Button>
                    <MostTextField register={props.register} name="sql_condition" label={t("Condizione SQL")} multiline={true} rows={5} value={props.sql_condition} onChange={sql_conditionOnChange} />
                </div>
                </Box>
            </div>
          </React.Fragment>
        )
    }
    //log.debug("TabCampiCondizioni")
    return (
      <Box border={1} borderRadius={4} className="box" >
        <AppBar position="static">
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={t("Campi da esportare")} id="tab-0" />
                <Tab label={t("Condizioni di ricerca")} id="tab-1" />
            </Tabs>
        </AppBar>
        { tab === "tab0" ?
            <div className="blackColor divInBox">
                {t("Campi anagrafici")}:
                <ul>
                { props.row.field_list.PATIENT.length ?
                    <li>
                    { props.row.field_list.PATIENT.map((item, index) => (
                        <span key={"ca"+index}> {item} &nbsp;</span>
                    )) }
                    </li>
                : null }
                </ul>
                {t("Campi medici")}:
                <ul>
                { Object.keys(props.row.field_list.MEDICAL_DATA).map((item, index) => (
                    <React.Fragment>
                    { props.row.field_list.MEDICAL_DATA[item].length ?
                        <li key={"cm"+index}> {item} :
                        { props.row.field_list.MEDICAL_DATA[item].map((item, index1) => (
                            <span key={"cmm"+index+"_"+index1}> {item} &nbsp;</span>
                        ))}
                        </li>
                    : null }
                    </React.Fragment>
                )) }
                </ul>
            </div>
        : null }
        { tab === "tab1" ?
            <div className="blackColor divInBox">
                { props.row.sql_condition ?
                    <div>
                        {t("Condizione SQL")}: <br />
                        {props.row.sql_condition}
                    </div>
                : props.row.filter_list && props.row.filter_list.PATIENT && props.row.field_list.MEDICAL_DATA ?
                    <div>
                        {t("Condizioni campi anagrafici")}: <br />
                        <ul>
                        { props.row.filter_list.PATIENT.length ?
                            props.row.filter_list.PATIENT.map((item, index) => (
                                <li key={"cca"+index}> {item[0]+" "+item[1]+" "+item[2]}</li>
                            ))
                        :
                            <span>{t("Nessuna condizione")}</span>
                        }
                        </ul>
                        {t("Condizioni campi medici")}: <br />
                        <ul>
                        { props.row.filter_list.MEDICAL_DATA.length ?
                            props.row.filter_list.MEDICAL_DATA.map((item, index) => (
                                <li key={"cmd"+index}> {item[0]+" "+item[1]+" "+item[2]}</li>
                            ))
                        :
                            <span>{t("Nessuna condizione")}</span>
                        }
                        </ul>
                    </div>
                :
                    <div>
                        {t("Nessuna condizione")}
                    </div>
                }
            </div>
        : null }
      </Box>
)}
export const MTabCampiCondizioni = React.memo(TabCampiCondizioni)
