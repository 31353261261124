import React, { useCallback, useState, } from "react";
import log from 'loglevel';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { DataTable } from '../components/DataTable';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MFiltroForm } from './MFiltroForm';
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { MostAutocomplete, MostButton2, MostSubmitButton, } from "../components/MostComponents";
import MyAxios, { check_and_download, } from "../MyAxios";
import { dataGridMakeIdUnique, } from "../Utils";
import { roleString, options_formatofile, authorized, } from '../UtilsInthem'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const FiltroExport = (props) => {
    const { control, handleSubmit, errors, } = useForm();
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const { t, } = useTranslation();
    const history = useHistory();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

    const columns = [
        {field: "DB_ID", headerName: "DB_ID" }
    ]
    if (props.filterId !== "__new__") {
        const fl = props.row.field_list
        for(let i in fl.PATIENT) {
            const r = fl.PATIENT
            columns.push({field: r[i], headerName: r[i], width: 200 })
        }
        for(let i in fl.MEDICAL_DATA) {
            const r = fl.MEDICAL_DATA
            columns.push({field: r[i], headerName: r[i], width: 200 })
        }
    }

    const onSubmitDownload = (vals) => {
        log.debug("onSubmitDownload vals",vals)
        if (props.row.rstatus === 'SUSPEND') {
            appAlert(t("Filtro disabilitato"))
            return
        }
        setDisabledButs(true)
        setLoading(true)
        setShowdatagrid(false)
        let format = vals.format.value
        if (vals.format.value === "video")
            format = 'JSON'
        const jdata = {
            action: 'RUN',
            format: format,
            id: props.row.id,
            as_role: props.mode.startsWith("ca") ? "CenterAdmin" : "DataManager",
        }
        MyAxios.post("/inthem_query", jdata, {
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            setLoading(false)
            const fileName = 'filtro_'+props.row.id+'.'+format.toLowerCase()
            const res = check_and_download(response,format,vals.format.value,fileName,1)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (vals.format.value === "video") {
                setRows([])
                setShowdatagrid(true)
                setRows(dataGridMakeIdUnique(res.rows,1))
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
            setLoading(false)
        })
    }

    //log.debug("FiltroExport")
    if (props.row.rstatus === 'SUSPEND') 
        return (
            <div className="margintop30">
                <Container component="main" maxWidth="md" className="blackColor">
                    <h3>{t("Filtro disabilitato")}</h3>
                </Container>
            </div>
        )
    return (
        <div className="margintop30">
        <Container component="main" maxWidth="md">
            <form onSubmit={handleSubmit(onSubmitDownload)} noValidate>
                    <Grid container spacing={1} alignItems="center" className="blackColor">
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="format" options={options_formatofile} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        <Grid item xs={8}>
                            <MostSubmitButton disabled={disabledButs} className="b_loginok" label={t("Esporta")} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={() => history.push(props.backUrl)} label={t("Indietro")} />
                        </Grid>
                        <Grid item xs={8} className="tcenter">
                            <PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={loading} />
                        </Grid>
                    </Grid>
                    </form>
        </Container>
        { showdatagrid ?
            <DataTable columns={columns} rows={rows} height={400} density={"compact"} />
        : null }
        </div>
)}
const MFiltroExport = React.memo(FiltroExport)

export const Filtro = (props) => {
    //log.debug("Filtro",props.row)
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const [modeFiltroForm, setModeFiltroForm] = useState(null)
    let initialTab = 0
    const [tabValue, setTabValue] = useState(initialTab)
    const [tab, setTab] = useState("tab"+initialTab)

    const changeTab = (tabNumber,fromPreSubmit) => {
        setTabValue(tabNumber)
        setTab("tab"+tabNumber)
        window.scrollTo(0, 0)
    }

    const handleTabChange = (e,tabNumber) => {
        // usciamo da modifica
        setModeFiltroForm(null)
        changeTab(tabNumber)
    }

    let backUrl
    if(props.mode==="ga_filtro")
        backUrl = "/ga_filtri"
    else if(props.mode==="ca_filtro")
        backUrl = "/ca_filtri"
    else
        backUrl = "/dm_filtri"

    let title
    if (props.filterId === "__new__") {
        title = t("Nuovo filtro")
    } else {
        title = t("Gestione filtro")+" "+props.row.name
    }

    const redirect = authorized(
        props.mode==="ga_filtro" ?  "GlobalAdmin" : 
            (props.mode==="ca_filtro" ? "CenterAdmin" : "DataManager"),
        userInfo)
    if(redirect)
        return redirect

    if (props.filterId === "__new__") {
        return (
        <div className="app-container">
            <Header backMode={backUrl} title={title} subtitle={roleString(props.mode,t)} />
            <div className="content-container contentWithToolbar">
                <Container component="main" maxWidth="md">
                <MFiltroForm mode={props.mode} backUrl={backUrl} filterId={props.filterId} row={props.row} medical_field_category={props.medical_field_category} personal_fields={props.personal_fields} options_medical={props.options_medical} options_personal={props.options_personal} modeFiltroForm={modeFiltroForm} setModeFiltroForm={setModeFiltroForm} />
                </Container>
            </div>
        </div>
        )
    }
    //log.debug("Filtro")

    return (
  <div className="app-container">
  <Header backMode={backUrl} title={title} subtitle={roleString(props.mode,t)} />
        <div className={"content-container "+ (modeFiltroForm==="updating" ? "contentWithTabAndToolbar":"contentWithToolbar")}>
            <AppBar position="absolute">
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label={t("Dettaglio")} id="tab-0" />
                    <Tab label={t("Esportazione")} id="tab-1" />
                </Tabs>
            </AppBar>
            <div className="margintop30">
                { tab === "tab0" ?
                    <Container component="main" maxWidth="md">
                    <MFiltroForm mode={props.mode} backUrl={backUrl} filterId={props.filterId} row={props.row} medical_field_category={props.medical_field_category} personal_fields={props.personal_fields} options_medical={props.options_medical} options_personal={props.options_personal} modeFiltroForm={modeFiltroForm} setModeFiltroForm={setModeFiltroForm} />

                    </Container>
                :
                    <MFiltroExport mode={props.mode} backUrl={backUrl} filterId={props.filterId} row={props.row} />
                }
            </div>
        </div>
  <Footer />
  </div>
  )
}
