import axios from "axios";
import log from 'loglevel';
import i18n from 'i18next';
import { set_cookie, downloadArrayBuffer } from './Utils';

const currentURL = window.location.protocol + "//" + window.location.host
let server_url
if (currentURL === "http://localhost:3000")
    server_url = "https://inthem.mostapps.it"
else
    server_url = currentURL
const be_url = server_url+"/inthem_be/"

const ax = axios.create({
  baseURL: be_url,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json;charset=UTF-8'
  },
});
ax.interceptors.request.use(request => {
  //log.debug('Request', JSON.stringify(request, null, 2))
  request.headers["Accept-Language"] = i18n.language
  log.debug('Request', request.method, request.url, request.data)
  return request
})
ax.interceptors.response.use(response => {
  //log.debug('Response:', JSON.stringify(response, null, 2))
  log.debug('Response:', response.config.url, response.status, response.data)
  //log.debug('Response:', response.config.url, response.status, response.data, JSON.stringify(response, null, 2))
  return response
})
export default ax;
export const app_server_url = server_url;
export const app_be_url = be_url;

export function check_response(response,msgWithoutSuccessField,noLogoutOnError) {
    // analizzo la risposta standard MOST:
    // 1. success true/false
    // 2. payload
    // ritorna un json con dentro il payload ed il booleano di success
    // in caso di errore, al json aggiungo l'errore (c'e' gia')
    //log.debug("CHECK_RESPONSE",response);
    if (response.status !== 200) {
        //let error = "CHECK_RESPONSE status " + response.status;
        let error = "status " + response.status;
        log.debug(error);
        response.data = { success: false, error: error }
    } else if (!msgWithoutSuccessField && !response.data.success) {
        if (typeof response.data.error === 'string' || response.data.error instanceof String) {
            if (response.data.error === "session required" || response.data.error.search("APIsession_required") !== -1) {
                log.error("session timeout: ", response.data.error);
                    // operazioni fatto da /logout
                    //localStorage.removeItem("token");
                    //setUserInfo({});
                set_cookie("pathnameOnUnauthAccess",window.location.pathname)
                if(!noLogoutOnError)
                    // va bene non mettere nello stack /logout, no history
                    window.location.replace('/logout');
            }
        } else {
            log.debug("response.data.error", response.data.error)
            response.data.error = JSON.stringify(response.data.error)
        }
    }
    return (response.data)
}

const getErrorFromAB = (ab) => {
    try {
        const s = String.fromCharCode.apply(null, new Uint8Array(ab))
        log.debug("getErrorFromAB",s)
        const obj = JSON.parse(s)
        if(obj.error)
            return (obj.error)
    } catch (error) {
    }
    return "Errore lato server"
}

// non dovrebbe arrivarci una risposta json, ma non si sa mai...
// e la ricostruiamo
// NOTA: la post|get deve avere "responseType: 'arraybuffer'"
export function check_and_download(response,be_format,fe_format,fileName,keepClientName,showInNewWin) {
    const res = check_response(response,1)
    if (res.success === false) {
        // ci e' arrivata risposta json con errore!
        log.error("check_and_download: received json with error")
        return res
    }
    const contentType = response.headers['content-type']
    if (be_format !== "JSON") {
        if (contentType === "application/json") {
            log.error("check_and_download: received json vs ",be_format)
            const error = getErrorFromAB(res)
            return {
                success: false,
                error: error
            }
        }
    }
    if (fe_format === "video") {
        return {
            success: true,
            rows: res,
        }
    }
    let ab = res
    if (be_format === 'JSON') {
        // in response arriva oggetto json non la stringa
        ab = response.request.response
    }
    if(!keepClientName) {
        try {
            const header_filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
            if (header_filename != null) {
                if (header_filename.charAt(0) === '"' && header_filename.charAt(header_filename.length-1) === '"')
                    fileName = header_filename.substring(1,header_filename.length-1)
                else
                    fileName = header_filename
            }
        } catch (error) { }
    }
    downloadArrayBuffer(ab,fileName,contentType,showInNewWin)
    return {
        success: true
    }
}

export function get_document(s3_id,showError,showInNewWin) {
    //log.debug("get_document",s3_id)
    const formData = new FormData()
    formData.append('s3_id', s3_id)
    const url = "/get_document"
    log.debug(url, [...formData.entries()])
    ax.post(url, formData, {
        responseType: 'arraybuffer',
    }).then((response) => {
        const res = check_and_download(response,null,null,null,null,showInNewWin)
        if(res.success === false) {
            log.error("Error: "+res.error)
            if(showError)
                showError(res.error)
            return
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        if(showError)
            showError(error.message?error.message:JSON.stringify(error));
    })
}

