import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MobileTitle } from './MobileTitle';
import { DataTable, } from '../components/DataTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized } from '../UtilsInthem'
import MyAxios, { check_response, } from "../MyAxios";
import { useHistory } from "react-router-dom";

export const PStudi = (props) => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    const [rows, setRows] = useState([])
    const history = useHistory();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const columns = [
      { field: 'id', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickView = () => {
            history.push("/pstudio_view/"+params.value)
          }
          return (
            <Tooltip title={t("Visualizza")}><IconButton className="gold" onClick={onClickView}><SearchIcon /></IconButton></Tooltip>
          )
        }
      },
      { field: 'title', headerName: t('Titolo'), width: 200, },
      { field: 'status', headerName: t('Stato'), width: 200, },
    ]

    useEffect(() => {
        const jdata = {
        }
        MyAxios.post("/patient/MyStudies", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          setRows(data.my_studies)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,])

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect

    const title = t("Gli studi")
    return (
  <div className="app-container">
    <Header title={title} />
    <div className="p_content-container displayFlex flexflowColumn">
        <MobileTitle title={title} />
        <div className="whiteback flex1">
            <DataTable columns={columns} rows={rows} density={"compact"} disableSelectionOnClick={true} />
        </div>
    </div>
    <Footer />
  </div>
    );
};
