import React, { useState, useEffect, useCallback, } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MobileTitle } from './MobileTitle';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { nthIndex, isMobile, } from '../Utils'
import { authorized } from '../UtilsInthem'
//import MyAxios from "../MyAxios";
import Grid from "@material-ui/core/Grid";
import { useForm, } from "react-hook-form";
import { MostRadioGroup, MostSubmitButton, MostButton2, Loading, MostAutocomplete, } from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";
import { useHistory } from "react-router-dom";

export const Sf36New = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    //const [disabledButs, setDisabledButs] = useState(false)
    const disabledButs = false
    const history = useHistory();
    const { control, handleSubmit, errors, } = useForm();
    const [modello, setModello] = useState(null)
    const [sf36id, setSf36id] = useState(null)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
        setContent(text);
        setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }

    // scroll su primo errore della form (serve id in <li>)
    useEffect(() => {
       let errorsArray = Object.keys(errors)
       if (errorsArray.length) {
         let id = errorsArray[0]
         // abbiamo id solo dei punti, non dei sottopunti
         const u = nthIndex(id,"_",2)
         if (u > -1)
            id = id.substr(0,u)
         let el = document.getElementById(id)
         if (el) {
            el.scrollIntoView()
         }
       }
    }, [errors])

    useEffect(() => {
        // xxx id (sf36) preso da dove? xxx
        // xxx oppure in BE in base a paziente?
        const jdata = {
            id: 'sf36',
        }
        MyAxios.post("/patient/GetSF36", jdata)
        .then((response) => {
            const data = check_response(response);
            if(!data.success) {
                log.error(data.error)
                appAlert(data.error)
                return
            }
            setModello(data.sf36.points)
            setSf36id(data.sf36.id)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,])

    const onSubmit = (vals) => {
        // se mobile, da Native in vals solo value e non oggetto value/label
        // MostAutocomplete selectOnMobile 
        //      control: name={"point_"+itemIndex+"_"+domandaIndex}
        log.debug(vals)
        const ret = []
        for(let i in modello) {
            if(modello[i].tipo === "radio") {
                log.debug('radio')
                ret.push(parseInt(vals['point_'+i]))
            } else {
                log.debug('multi')
                const p = []
                for(let j in modello[i].domande) {
                    if(isMobile())
                        // ci si aspetta integer non string, Native da' string
                        p.push(parseInt(vals['point_'+i+'_'+j]))
                    else
                        p.push(vals['point_'+i+'_'+j].value)
                }
                ret.push(p)
            }
        }
        log.debug(ret);
        const jdata = {
            points: ret,
            sf36id: sf36id,
        }
        MyAxios.post("/patient/NewSF36", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          appAlert2(t("Le tue risposte sono state registrate"),() => {
            history.push("/myconsent")
          })
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    const redirect = authorized("patient",userInfo)
    if(redirect)
        return redirect

    if(!modello)
        return <Loading />

    // in debug per riempire automaticamente form, mettere a 1 (non committare...)
    const autoFill = 0

    // https://www.pluralsight.com/guides/how-to-implement-a-component-%22loop%22-with-react
    const title = t("Nuovo Sf36")
    return (
  <div className="app-container">
    <Header title={title} />
    <div className="p_content-container" >
    <MobileTitle title={title} />
    <Container component="main" maxWidth="md">
        <div className="blackColor">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ol className="modulo">
        {modello.map((item,itemIndex)=>{
            return (
                <li id={"point_"+itemIndex} key={"li"+itemIndex}>
                    <div dangerouslySetInnerHTML={{__html: "<b>"+item.testo+"</b>"}} />
                    <div><br /><i>({item.help})</i></div><br />
                    {item.tipo === "radio"?
                          <MostRadioGroup
                            control={control}
                            rules={{ required: true }}
                            name={"point_"+itemIndex}
                            options={item.risposte}
                            errors={errors}
                            defaultValue={autoFill ? ""+item.risposte[0].value : null}
                          />
                    :
                        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
                        {item.domande.map((domanda,domandaIndex)=>{
                            return (
                              <React.Fragment key={"RF"+domandaIndex}>
                                <Grid item xs={12} sm={6} className="margint12">
                                    {domanda}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <MostAutocomplete selectOnMobile name={"point_"+itemIndex+"_"+domandaIndex} control={control} options={item.risposte[domandaIndex]} required={true} rules={{ required: true }} defaultValue={autoFill ? item.risposte[domandaIndex][0] : null} errors={errors} />
                                </Grid>
                              </React.Fragment>
                            )
                        })}
                        </Grid>
                    }
                </li>
            )
        })}
        </ol>
        <Grid container spacing={1} alignItems="flex-start" className="blackColor">
            <Grid item xs={8}>
                <MostSubmitButton disabled={disabledButs} label={t("Conferma")} id="Conferma" />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} onClick={() => history.push("/myconsent")} label={t("Annulla")} className="margin2416" id="Back" />

            </Grid>
        </Grid>
        </form>
        </div>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
