import React, { useState, Suspense } from 'react';
import log from 'loglevel';
import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { itIT } from '@material-ui/data-grid';
import CssBaseline from "@material-ui/core/CssBaseline";
import { GlobalHooksProvider, createGlobalHook } from '@devhammed/use-global-hook'
import GuardedRoute from './GuardedRoute';
import { Home } from './Home';
import { logInit } from './Utils';
import { Login, Logout } from './Login';
import { MyConsent } from './patient/MyConsent';
import { PDati } from './patient/PDati';
import { PComunicazioni } from './PComunicazioni';
import { PStudi } from './patient/PStudi';
import { PStudio } from './patient/PStudio';
import { Sf36 } from './patient/Sf36';
import { Sf36New } from './patient/Sf36New';
import { Consenso } from './patient/Consenso';
import { ForgottenPasswd } from './auth/ForgottenPasswd';
import { ForgottenPasswdPatient } from './auth/ForgottenPasswdPatient';
import { ChangePasswd } from './auth/ChangePasswd';
import { UHome } from './user/UHome';
import { Filtri } from './user/Filtri';
import { Questionari } from './user/Questionari';
import { GlobalConfig } from './user/GlobalConfig';
import { Config } from './user/Config';
import { TabelleServizio } from './user/TabelleServizio';
import { Log } from './user/Log';
import { Studi } from './user/Studi';
import { Comunicazioni } from './user/Comunicazioni';
import { Centri } from './user/Centri';
import { CentroChangePasswd } from './user/CentroChangePasswd';
import { DownloadDiagnosiTipichelanti } from './user/DownloadDiagnosiTipichelanti';
import { PazientiDati } from './user/PazientiDati';
import { Ecrf } from './user/Ecrf';
import { Utenti } from './user/Utenti';
import { Loading, Timeout, NoMatch } from './components/MostComponents';
import { getLang } from './i18n';
import AlertDialog from './AlertDialog';
import * as locales from '@material-ui/core/locale';
// import { ProvideAuth } from "./use-auth";
//import logo from './Smartag.png';
import './App.css';

logInit()

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <Loading />
  </div>
);

const alertStore = createGlobalHook('alertStore', () => {
  const [alert1, setAlert1] = useState(false);
  const [content, setContent] = React.useState("")
  return { alert1, setAlert1, content, setContent }
})
const alert2Store = createGlobalHook('alert2Store', () => {
  const [alert2, setAlert2] = useState(false);
  const [content2, setContent2] = React.useState("")
  const [onClose2, setOnClose2] = React.useState(null)
  return { alert2, setAlert2, content2, setContent2, onClose2, setOnClose2 }
})
const confirmStore = createGlobalHook('confirmStore', () => {
  const [confirm1, setConfirm1] = useState(false);
  const [cContent, setCContent] = React.useState("")
  const [onConfirm, setOnConfirm] = React.useState(null)
  return { confirm1, setConfirm1, cContent, setCContent, onConfirm, setOnConfirm }
})
const userStore = createGlobalHook('userStore', () => {
  const [userInfo, setUserInfo] = useState({});
  return { userInfo, setUserInfo }
})

export default function App() {

  const { i18n } = useTranslation();
  let locale = null // enUS
  if (getLang(i18n.language) === "it")
    locale = "itIT"

  const timeout = 60 * 60 * 1000
  const [idleTimeout, setIdleTimeout] = useState(false);
  const handleOnIdle = () => {
    setIdleTimeout(true)
  }
  const resetIdleTimeout = () => {
    log.debug("resetIdleTimeout")
    setIdleTimeout(false)
    reset()
  }

  const {
    reset
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  if(window.ethereum)
    window.ethereum.autoRefreshOnNetworkChange = false;

  window.__most = {}
  window.__most.palette = {
      primary: {
        main: '#E1E002',        // colore molto chiaro
        contrastText: '#000000',
        // non usato da react
        darkColor: '#AAAA00'    // colore medio tra primary e secondary
      },
      // secondary : usare button variant="outlined" 
      // per fare button con colore di sfondo, usare variant="contained", main: colore sfondo, contrastText: colore scritta
      secondary: {
        main: '#777700',        // colore scuro
        contrastText: '#ffffff',
      },
      /*
      background: {
        default: '#999999',
      },
      */
  }

  const theme = createMuiTheme({
        palette: window.__most.palette,
      },
      // https://material-ui.com/guides/localization/
      locale ? locales[locale] : null,
      // https://material-ui.com/components/data-grid/localization/
      getLang(i18n.language) === "it" ? itIT : null
  );

  return (
  <ThemeProvider theme={theme}>
   <CssBaseline />
   <GlobalHooksProvider hooks={[ alertStore, alert2Store, confirmStore, userStore ]}>
    <Suspense fallback={<Loader />}>
    <Timeout idleTimeout={idleTimeout} />
      <div className="App">
        <Router>
          <Switch>
            <Route path="/login"> <Login mode="user" resetIdleTimeout={resetIdleTimeout} />  </Route>
            <Route path="/loginp"> <Login mode="patient" resetIdleTimeout={resetIdleTimeout} />  </Route>
            <Route path="/logout">  <Logout />  </Route>
            <Route path="/forgot_password">  <ForgottenPasswd />  </Route>
            <Route path="/forgot_password_patient">  <ForgottenPasswdPatient />  </Route>
            {/*
            <Route path="/opusdocs" render={() => (window.location = "/OpusDocs")} />
            <Route path="/opusdocs0" render={() => (window.open("/OpusDocs", "_blank"))} />
            */}
            <GuardedRoute path='/changepasswd' key="changepasswd" component={ChangePasswd} />
            <GuardedRoute path='/uhome' key="uhome" component={UHome} />
            <GuardedRoute path='/questionari' key="questionari" component={Questionari} />
            <GuardedRoute path='/ga_filtri' key="ga_filtri" component={Filtri} />
            <GuardedRoute path='/ga_filtro/:filterId' key="ga_filtro" component={Filtri} />
            <GuardedRoute path='/dm_filtri' key="dm_filtri" component={Filtri} />
            <GuardedRoute path='/dm_filtro/:filterId' key="dm_filtro" component={Filtri} />
            <GuardedRoute path='/ca_filtri' key="ca_filtri" component={Filtri} />
            <GuardedRoute path='/ca_filtro/:filterId' key="ca_filtro" component={Filtri} />
            <GuardedRoute path='/myconsent' key="myconsent" component={MyConsent} />
            <GuardedRoute path='/sf36' key="sf36" component={Sf36} />
            <GuardedRoute path='/sf36_new' key="sf36_new" component={Sf36New} />
            <GuardedRoute path='/consenso' key="consenso" component={Consenso} />
            <GuardedRoute path='/pdati' key="pdati" component={PDati} />
            <GuardedRoute path='/pcomunicazioni' key="pcomunicazioni" component={PComunicazioni} />
            <GuardedRoute path='/pcomunicazione/:id' key="pcomunicazione" component={PComunicazioni} />
            <GuardedRoute path='/pstudi' key="pstudi" component={PStudi} />
            <GuardedRoute path='/pstudio/:studioId' key="pstudio" component={PStudio} />
            <GuardedRoute path='/pstudio_view/:studioId' key="pstudio_view" component={PStudio} />
            <GuardedRoute path='/globalconfig' key="globalconfig" component={GlobalConfig} />
            <GuardedRoute path='/config' key="config" component={Config} />
            <GuardedRoute path='/tabelleservizio' key="tabelleservizio" component={TabelleServizio} />
            <GuardedRoute path='/ga_studiclinici' key="ga_studiclinici" component={Studi} />
            <GuardedRoute path='/ga_studio/:studioId' key="ga_studio" component={Studi} />
            <GuardedRoute path='/dm_studiclinici' key="dm_studiclinici" component={Studi} />
            <GuardedRoute path='/dm_studio/:studioId' key="dm_studio" component={Studi} />
            <GuardedRoute path='/ca_studiclinici' key="ca_studiclinici" component={Studi} />
            <GuardedRoute path='/ca_studio/:studioId' key="ca_studio" component={Studi} />
            <GuardedRoute path='/comunicazioni' key="comunicazioni" component={Comunicazioni} />
            <GuardedRoute path='/comunicazione/:id' key="comunicazione" component={Comunicazioni} />
            <GuardedRoute path='/log' key="log" component={Log} />
            <GuardedRoute path='/questionario/:qId' key="questionario" component={Questionari} />
            <GuardedRoute path='/centri' key="centri" component={Centri} />
            <GuardedRoute path='/centrochangepasswd' key="centrochangepasswd" component={CentroChangePasswd} />
            <GuardedRoute path='/downloaddiagnositipichelanti' key="downloaddiagnositipichelanti" component={DownloadDiagnosiTipichelanti} />
            <GuardedRoute path='/centro/:centerId' key="centro" component={Centri} />
            <GuardedRoute path='/pazienti-dati' key="pazienti-dati" component={PazientiDati} />
            <GuardedRoute path='/ecrf' key="ecrf" component={Ecrf} />
            <GuardedRoute path='/utenti' key="utenti" component={Utenti} />
            <GuardedRoute path='/utente/:userId' key="utente" component={Utenti} />
            <Route exact path="/">  <Home />  </Route> 
            <Route> <NoMatch /> </Route>
          </Switch>
        </Router>
      </div>
    </Suspense>
    <AlertDialog name="alert"/>
    <AlertDialog name="alert2"/>
    <AlertDialog name="confirm"/>
   </GlobalHooksProvider>
  </ThemeProvider>
  );
}
