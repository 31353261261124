import React, { useState, } from "react";
import log from 'loglevel';
import MyAxios from "./MyAxios";
import { Header } from './Header';
import { Footer } from './Footer';
import { SignIn } from './auth/SignIn';
import { SignInPatient } from './auth/SignInPatient';
import { Redirect } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { Loading, } from './components/MostComponents';

export const Login = (props) => (
  <div>
    <Header empty={true} />
    { props.mode === "user" ?
        <SignIn resetIdleTimeout={props.resetIdleTimeout} />
    :
        <SignInPatient resetIdleTimeout={props.resetIdleTimeout} />
    }
    <Footer />
  </div>
);

export function Logout() {
  const [loading, setLoading] = useState(true)
  const { setUserInfo } = useGlobalHook('userStore')
  //localStorage.removeItem("token")
  React.useEffect(() => {
    log.debug("Logout")
    setUserInfo({})
    MyAxios.get('/userlogout')
        .then((response) => {
        })
        .catch(function (error) {
        })
        .then(() => {
            setLoading(false)
        })
  }, [setUserInfo])

  if(loading)
    return <Loading />
  //log.debug("Logout" + localStorage.getItem("token"))
  // user o patient?
  return (<Redirect to='/' />)
}
