import React, { useState } from "react";
import log from 'loglevel';
import { Route, Redirect } from "react-router-dom";
import { now } from "./Utils"
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, {check_response} from "./MyAxios";
import { Loading } from './components/MostComponents';

const GuardedRoute = ({ component: Component, ...rest }) => {
    //log.debug("rest",rest);

    // prop forcereload forza azzeramento stato ad ogni accesso
    // della componente (aggiunta key univoca)
    // NOTA: non si puo' usare appAlert() altrimenti
    //       a fronte dell'alert viene ricaricata completamente la pagina
    let forcereload = false;
    if (rest.forcereload === "true")
        forcereload = true;
    //log.debug("forcereload",forcereload);

    // il path lo inoltriamo come prop mode alla component
    let mode =  (rest.path).substring(1);
    let slash = mode.indexOf("/");
    if(slash !== -1)
        mode = mode.substr(0,slash);

    const { userInfo, setUserInfo } = useGlobalHook('userStore');
    const [loading, setLoading] = useState(true);

    const isonow = () => new Date().toISOString();

    React.useEffect(() => {
        log.debug("--- "+now()+" GuardedRoute",mode);
        //log.debug("call get_userinfo");
        let url = 'get_userinfo';
        MyAxios.get(url)
          .then(response => {
            const res = check_response(response);
            if (res.success) {
                //log.debug("userInfo",res.user);
                setUserInfo(res.user)
            } else {
                log.error("get_userinfo error",response);
                setUserInfo({})
            }
          })
          .catch(function (error) {
            log.error(error);
            setUserInfo({})
          })
          .then(function () {
            // always executed
            setLoading(false);
          });
    }, [setUserInfo,mode]);

    //log.debug("GuardedRoute: loading:",loading,"userInfo",userInfo,"mode",mode);
    if(loading)
        return(
            <Loading />
        )
    // redirect a login: dovremmo scoprire se siamo user o patient
    return(
        <Route {...rest} render={(rest) => (
            userInfo.username ?
                (forcereload ? (
                        <Component {...rest} mode={mode} key={isonow()} />
                    ) : (
                        <Component {...rest} mode={mode}/>
                    ))
                : <Redirect to='/' />
        )} />
    )
}

export default GuardedRoute;
