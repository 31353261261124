import React, { useState, useCallback, useEffect, } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MobileTitle } from './MobileTitle';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized, } from '../UtilsInthem'
import { showDoc } from '../Utils'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import MyAxios, {check_response} from "../MyAxios";
import { options_si_no, MostRadioGroup, MostButton, Loading, } from "../components/MostComponents";
import { useHistory } from "react-router-dom";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SearchIcon from '@material-ui/icons/Search';

export const PStudio = (props) => {
  const history = useHistory();
  const { t, } = useTranslation();
  const { userInfo } = useGlobalHook('userStore');
  let initialTab = 0
  const [tabValue, setTabValue] = useState(initialTab)
  const [tab, setTab] = useState("tab"+initialTab)
  const [row, setRow] = useState(null)
  const [consenso, setConsenso] = useState(null)
  const [disabledButs, setDisabledButs] = useState(false)
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
  }

  const studioId = props.match.params.studioId

  const changeTab = (tabNumber,fromPreSubmit) => {
    setTabValue(tabNumber)
    setTab("tab"+tabNumber)
  }
  const handleTabChange = (e,tabNumber) => {
    changeTab(tabNumber)
  }

  const toTab1 = () => {
    changeTab(1)
  }

  useEffect(() => {
    const jdata = {
        action: 'LIST',
    }
    const formData = new FormData()
    const api = "/clinical_study"
    formData.append('jpayload', JSON.stringify(jdata))
    log.debug(api, [...formData.entries()])
    log.debug('jpayload',jdata)
    MyAxios.post(api, formData)
    .then((response) => {
      const data = check_response(response);
      if(!data.success) {
        log.error(data.error)
        appAlert(data.error)
        return
      }
      let found = 0
      for (let i in data.rows) {
        if(data.rows[i].id === studioId) {
          setRow(data.rows[i])
          found = 1
          break
        }
      }
      if(!found)
        history.push("/myconsent")
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }, [appAlert,studioId,userInfo,history,])

  const consensoOnChange = (e) => {
    setConsenso(e.target.value)
  }

  const submit = () => {
    if(consenso === null) {
        appAlert(t("Inserisci la risposta"))
        return
    }
    if(needConsentsUpdate && consenso === "1") {
        appAlert(t("Non è possibile partecipare senza fornire i consensi richiesti"))
        return
    }
    setDisabledButs(true)
    const jdata = {
        "StudyCode": studioId,
        "status": consenso === "1" ? "ConsensoSI" : "ConsensoNO"
    }
    MyAxios.post("/patient/JoinStudy", jdata)
    .then((response) => {
        setDisabledButs(false)
        const res = check_response(response)
        if (res.success) {
            appAlert2(t("Grazie. La tua risposta è stata registrata"),() => {
                history.push("/myconsent")
            })
        } else {
            log.error("Error: "+res.error);
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
  }

  function Informazioni() {
    return (
        <React.Fragment>
            <Grid item xs={4}> Codice </Grid> <Grid item xs={8}> {row.id} </Grid>
            <Grid item xs={4}> Titolo </Grid> <Grid item xs={8}> {row.title} </Grid>
            <Grid item xs={4}> Descrizione </Grid> <Grid item xs={8}> {row.description} </Grid>
            { row.video_url ?
                <React.Fragment>
                <Grid item xs={4}> {t("Video presentazione")}</Grid> 
                <Grid item xs={8}> 
                    <a href={row.video_url} target="_blank" rel="noreferrer">
                      <IconButton className="roundButton">
                        <OndemandVideoIcon />
                      </IconButton>
                    </a> 
                </Grid>
                </React.Fragment>
            : null }
            { row.s3_id ?
                <React.Fragment>
                <Grid item xs={4}> {t("Documento presentazione")}</Grid> 
                <Grid item xs={8}> 
                      <IconButton className="roundButton" onClick={() => showDoc(row.s3_id)}>
                        <SearchIcon />
                      </IconButton>
                </Grid>
                </React.Fragment>
            : null }
            { row.clinical_study_type_detail.extraeu || row.clinical_study_type_detail.f || row.clinical_study_type_detail.p_db ?
                <React.Fragment>
                <Grid item xs={4}> {t("Autorizzazioni richieste")} </Grid> 
                <Grid item xs={8}>
                    <ul className="ulInGrid">
                    <li> {t("trasferimento dati medici")}</li>
                    <li> {t("utilizzo dati per ricerche")}</li>
                    { row.clinical_study_type_detail.extraeu ?
                        <li> {t("trasferimento dati verso Enti Extra UE")} </li>
                    : null }
                    { row.clinical_study_type_detail.p_db ?
                        <li> {t("trasferimento dati verso DataBase Pubblici")} </li>
                    : null }
                    { row.clinical_study_type_detail.f ?
                        <li> {t("trasferimento dati verso Case Farmaceutiche")} </li>
                    : null }
                    </ul>
                </Grid>
                </React.Fragment>
            : null }
        </React.Fragment>
    )
  }

  const redirect = authorized("patient",userInfo)
  if(redirect)
    return redirect

  if(!row)
    return <Loading />

  let needConsentsUpdate = false
  if(!userInfo.c_db_m
  || (!userInfo.c_extraeu && row.clinical_study_type_detail.extraeu)
  || (!userInfo.c_f && row.clinical_study_type_detail.f)
  || (!userInfo.c_p_db && row.clinical_study_type_detail.p_db))
    needConsentsUpdate = true

  const defaultValue = null

  const title = t("Studio")+" "+studioId

  if(props.mode==="pstudio_view") {
    return (
      <div className="app-container">
      <Header title={title} backMode="/pstudi" />
      <div className="p_content-container">
        <MobileTitle title={title} />
        <div className="blackColor margintop30">
          <Container component="main" maxWidth="md">
            <Grid container spacing={1} alignItems="flex-start">
                <Informazioni />
            </Grid>
          </Container>
        </div>
      </div>
      </div>
    )
  }

  return (
  <div className="app-container">
  <Header title={title} />
  <div className="p_content-container p_contentWithTitleAndToolbar">
  <Container component="main" maxWidth="md">
    <MobileTitle title={title} className="p_absolutemobiletitle" />
    <AppBar position="absolute">
        <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={t("Informazioni")} id="tab-0" />
            <Tab label={t("Consenso allo studio")} id="tab-1" />
        </Tabs>
    </AppBar>
    { tab === "tab0" ?
        <div className="blackColor">
        <h2> </h2>
        <Grid container spacing={1} alignItems="flex-start">
            <Informazioni />
            { needConsentsUpdate ?
                <React.Fragment>
                <Grid item xs={12} className="important">
                    <p>
                    {t("consensi non permettono")}
                    </p>
                    <p>
                    Per partecipare dovresti autorizzare:
                    <ul className="ulInGrid">
                    { !userInfo.c_db_m ?
                        <li> {t("consenso punto 2")}</li>
                    : null }
                    { !userInfo.c_s ?
                        <li> {t("consenso punto 3")}</li>
                    : null }
                    { !userInfo.c_extraeu && row.clinical_study_type_detail.extraeu ?
                        <li> {t("consenso punto 5")}</li>
                    : null }
                    { !userInfo.c_p_db && row.clinical_study_type_detail.p_db ?
                        <li> {t("consenso punto 6")}</li>
                    : null }
                    { !userInfo.c_f && row.clinical_study_type_detail.f ?
                        <li> {t("consenso punto 7")}</li>
                    : null }
                    </ul>
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={() => history.push("/consenso")} label={t("Modifica i tuoi consensi")} id="consenso"/>
                </Grid>
                <Grid item xs={6}>
                    <MostButton className="b_loginok" onClick={toTab1} label={t("Decidi di non partecipare")} id="nopart"/>
                </Grid>
                </React.Fragment>
            :
                <React.Fragment>
                <Grid item xs={12} className="important">
                    {t("I consensi che ci hai dato precedentemente ti permettono di partecipare a questo studio")}
                </Grid>
                <Grid item xs={12}>
                    <MostButton className="b_loginok" onClick={toTab1} label={t("Decidi se partecipare allo studio")} id="decidi"/>
                </Grid>
                </React.Fragment>
            }
        </Grid>
        </div>
    :
        <div>
            <h2> {t("Partecipazione allo studio")} {row.title} </h2>
            <div className="blackColor">
            <Grid container spacing={1} alignItems="flex-start">
            { 0 || needConsentsUpdate ?
                <Grid item xs={12} className="important">
                    {t("NOTA")}: {t("consensi non permettono")}
                </Grid>
            : null }
                <Grid item xs={12}>
                <b>{t("Acconsenti a partecipare allo studio?")}</b>
                <MostRadioGroup
                    name={"consenso"}
                    options={options_si_no(t)}
                    defaultValue={defaultValue}
                    value={consenso}
                    onChange={consensoOnChange}
                  />
                </Grid>
                <Grid item xs={12}>
                    <MostButton disabled={disabledButs} className="b_loginok" label={t("Conferma")} onClick={submit} id="conferma"/>
                </Grid>
            </Grid>
            </div>
        </div>
    }

  </Container>
  </div>
  <Footer />
  </div>
)
}

