import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import Container from "@material-ui/core/Container";
import { Header } from '../Header';
import { Footer } from '../Footer';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useHistory } from "react-router-dom";
import { options_tabelleservizio, options_formatofile_download, options_formatofile, authorized, } from '../UtilsInthem'
import { dataGridMakeIdUnique, prettyJson } from '../Utils'
import MyAxios, { check_and_download, check_response } from "../MyAxios";
import { S3Field, MostAutocomplete, MostButton2, MostSubmitButton } from "../components/MostComponents";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { DataTable } from '../components/DataTable';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const TabelleServizio = (props) => {
    //log.debug("TabelleServizio")
    const { userInfo } = useGlobalHook('userStore');
    const history = useHistory();
    const { control, handleSubmit, errors, } = useForm();
    const { t, } = useTranslation();
    const [options_formatofile_download_t] = useState(options_formatofile_download(t))
    const [loading, setLoading] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [mode, setMode] = useState("download")
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [result, setResult] = useState()
    const [uploadInfo, setUploadInfo] = useState(null);
    const [uploadTarget, setUploadTarget] = useState(null);
    const [disabledButs, setDisabledButs] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

    const options_azioni = [
        {value: "APPEND", label: t("Aggiungi record")},
        {value: "CHANGE", label: t("Modifica i record")},
        {value: "REPLACE", label: t("Azzera tabella e inserisci record")},
    ]

    useEffect(() => {
        for (let i in userInfo.roles)
            if (userInfo.roles[i].role === "CenterAdmin") {
                //setCenter(userInfo.roles[i].center)
                break
            }
    }, [userInfo.roles])

    const onSubmitDownload = (vals) => {
        log.debug("onSubmitDownload vals",vals)
        setResult("")
        setShowdatagrid(false)
        let format = vals.format.value
        if (vals.format.value === "video")
            format = 'JSON'
        const formData = new FormData()
        formData.append('table_name', vals.tablename.value)
        formData.append('format', format)
        // xxx
        const url = "/admin/GetConfTable"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" },
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            const fileName = vals.tablename.value+'.'+format.toLowerCase()
            const res = check_and_download(response,format,vals.format.value,fileName,1)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (vals.format.value === "video") {
                setRows([])
                setShowdatagrid(true)
                setColumns(vals.tablename.columns)
                // tabelle con campo ID non chiave? data-grid si arrabbia
                // bisogna creare id come chiave e rinominare campo id
                if(vals.tablename.idIsNotKey)
                    setRows(dataGridMakeIdUnique(res.rows))
                else if(vals.tablename.missingId)
                    setRows(dataGridMakeIdUnique(res.rows,1))
                else
                    setRows(res.rows)
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    const onSubmitUpload = (vals) => {
        setResult("")
        if (!uploadInfo) {
            appAlert(t("File non scelto"))
            return
        }
        log.debug("uploadInfo",uploadInfo,"vals",vals)
        setDisabledButs(true)
        setLoading(true)
        const formData = new FormData()
        formData.append('table_name', vals.tablename.value)
        formData.append('format', vals.format.value)
        formData.append('action', vals.action.value)
        formData.append('table_file', uploadInfo[0])
        const url = "/admin/ConfTable"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
            //alert(JSON.stringify(response));
            //log.debug(response);
            setDisabledButs(false)
            setLoading(false)
            const res = check_response(response)
            if (res.success) {
                log.debug(res)
                if(res.report.ko)
                    appAlert(t("ATTENZIONE")+": "+res.report.ko+" "+t("record non importati"))
                else
                    appAlert(t("Caricamento terminato"))
                setResult(t("Record importati")+": "+res.report.ok+"<br />"+t("Record non importati")+": "+res.report.ko+"<br /><br />"+t("Dettaglio importazione")+":<br /><pre>"+prettyJson(res.report.detail)+"</pre>")
            } else {
                log.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
            setLoading(false)
        })
        .then(function () {
            // se cambia file uploadato, fallisce nuovo upload
            // con errore non significativo (Network Error)
            // mentre l'errore vero ERR_UPLOAD_FILE_CHANGED
            // viene restituito dopo in modo non catchabile
            // Resettiamo file dopo ogni upload
            if(uploadTarget)
              uploadTarget.value = null
            setUploadInfo(null)
        })
    }

    const back = () => {
        history.push('/globalconfig');
    }

    const redirect = authorized("GlobalAdmin",userInfo)
    if(redirect)
        return redirect

    let onSubmit = onSubmitUpload
    let buttonLabel = t("Carica")
    if(mode === "upload") {
    } else {
        onSubmit = onSubmitDownload
        buttonLabel = t("Recupera")
    }

    //log.debug(rows)
    //log.debug(columns)

    const handleTabChange = (e,t) => {
        setTabValue(t)
        setResult("")
        setShowdatagrid(false)
        if(t) {
            // carica
            setMode("upload")
        } else {
            // recupera
            setMode("download")
        }
    }

    return (
    <div className="app-container">
        <Header title={t("Tabelle di servizio")} backMode="/globalconfig" />
        {/* datatable: no flex (facciamo div che comprende tutto) */}
        <div className="content-container contentWithToolbar">
          <div>
          <Container component="main" maxWidth="md">
            <AppBar position="absolute">
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label={t("Recupero tabella")} id="tab-0" />
                    <Tab label={t("Caricamento tabella")} id="tab-1" />
                </Tabs>
            </AppBar>
                <div className="margintop30">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={1} alignItems="center" className="blackColor">
                      { mode === "upload" ?
                        <Grid item xs={12}>
                            <S3Field label={t("File da caricare")} s3_id={null} showError={appAlert} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} />
                        </Grid>
                      : null }
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="tablename" options={options_tabelleservizio} label={t("Tabella")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="format" options={mode === "download" ? options_formatofile_download_t : options_formatofile} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        {mode === "upload" ?
                            <Grid item xs={12}>
                                <MostAutocomplete control={control} name="action" options={options_azioni} label={t("Azione")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                            </Grid>
                        : null }
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={8}>
                            <MostSubmitButton disabled={disabledButs} className="b_loginok" label={buttonLabel} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={back} label={t("Indietro")} />
                        </Grid>
                        <Grid item xs={8} className="tcenter">
                        { loading ? (
                            <b>{t("Operazione in corso")}</b>
                        ) : null }
                        <PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={loading} />
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    </form>
                </div>
          </Container>
          </div>
          <div className="blackColor margin20all">
            <div dangerouslySetInnerHTML={{__html: result}} />
          </div>
          <div>
            { showdatagrid ?
                <DataTable columns={columns} rows={rows} height={600} density={"compact"} />
            : null }
          </div>
        </div>
        <Footer />
    </div>
    )
}
