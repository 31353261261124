import React, { useEffect, useState, useCallback } from "react";
import log from 'loglevel';
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { options_formatofile, options_formatofile_download, } from '../UtilsInthem'
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { options_si_no, S3Field, MostButton2, MostSubmitButton, MostButton, MostAutocomplete, } from "../components/MostComponents";
import MyAxios, { check_and_download, check_response, } from "../MyAxios";
import { addIdToRows, prettyJson, } from '../Utils'
import { getLang, } from '../i18n'
import { useForm } from "react-hook-form";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";

    const spinnerCss = css`
        display: block;
        margin: 0 auto;
    `;

const options_formatofile_download_pazienti = (t) => {
    return [
        {value: "CSV", label: "CSV"},
        {value: "JSON", label: "JSON"},
        {value: "XLSX", label: "XLSX"},
        {value: "XML", label: "XML"},
        {value: "video", label: t("visualizzazione e modifica a video")},
    ]
}

export const StudioGestionePazientiInternal = (props) => {
    log.debug("StudioGestionePazientiInternal",props)
    const { control, handleSubmit, errors, } = useForm();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [formatAC, setFormatAC] = React.useState(null)
    const [result, setResult] = useState("")
    const [options_si_no_t] = useState(options_si_no(t))
    const [options_formatofile_download_t] = useState(options_formatofile_download(t))
    const [options_formatofile_download_pazienti_t] = useState(options_formatofile_download_pazienti(t))
    const handleFormatACChange = (e,el) => {
        setFormatAC(el)
    }
    const [uploadInfo, setUploadInfo] = useState(null);
    const [uploadTarget, setUploadTarget] = useState(null);
    const [disabledButs, setDisabledButs] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState("recupera")
    const [rows, setRows] = useState([])
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])
    const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
    function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
    }

    /*
    const columns = [
        // field: 'center_code', 
        { field: 'center_id', headerName: 'Center_id', width: 200 },
        // field: 'study_code', 
        { field: 'name', headerName: t('Nome'), width: 200 },
        { field: 'surname', headerName: t('Cognome'), width: 200 },
        { field: 'approved', headerName: t('Inserito'), width: 200 },
        { field: 'study_patient_status', headerName: t('Stato'), width: 200 },
    ]
    */

    useEffect(() => {
        setShowdatagrid(false)
        setResult("")
        setFormatAC(options_formatofile_download_pazienti_t[4]) // video
    }, [props.forceReload,options_formatofile_download_pazienti_t,])

    function getRecord(k) {
        for (let i in rows) {
            if(rows[i].center_id === k)
                return rows[i]
        }
        return null
    }

    const onSubmitUpdate = (vals) => {
        log.debug("onSubmitUpdate: vals",vals)
        const v = []
        for (let k in vals) {
            if(vals[k]) {
                const r = getRecord(k)
                if(r) {
                    r.approved = vals[k].value === "1" ? true : false
                    delete r.id
                    v.push(r)
                } else
                    appAlert("record con center_id "+k+" non trovato")
            }
        }
        if (v.length === 0) {
            back2recupera()
            return
        }
        const jsonFile = new File([JSON.stringify(v)], 'patient_update.json', { type: 'application/json' })
        onSubmitUpload({format: {value: 'JSON'} },jsonFile)
    }

    const onSubmitUpload = (vals,jsonFile) => {
        if (!uploadInfo && !jsonFile) {
            appAlert(t("File non scelto"))
            return
        }
        log.debug("uploadInfo",uploadInfo,"vals",vals,"jsonFile",jsonFile)
        setDisabledButs(true)
        setLoading(true)
        const formData = new FormData()
        formData.append('FileStudyPatients', uploadInfo ? uploadInfo[0] : jsonFile)
        formData.append('format', vals.format.value)
        formData.append('centerCode', props.centro)
        const api = "/putStudyPatients"
        log.debug(api, [...formData.entries()])
        MyAxios.post(api, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
            setDisabledButs(false)
            setLoading(false)
            const res = check_response(response)
            if (res.success) {
                log.debug(res)
                if(res.report.ko)
                    appAlert(t("ATTENZIONE")+": "+res.report.ko+" "+t("record non importati"))
                else
                    appAlert2(t("Caricamento terminato"),() => {
                        if(jsonFile) {
                            back2recupera()
                        }
                    })
                setResult(t("Record importati")+": "+res.report.ok+"<br>"+t("Record non importati")+": "+res.report.ko+"<br /><br />"+t("Dettaglio importazione")+":<br /><pre>"+prettyJson(res.report.detail)+"</pre>")
            } else {
                log.error("Error: "+res.error);
                appAlert(res.error);
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
            setLoading(false)
        })
        .then(function () {
            // se cambia file uploadato, fallisce nuovo upload
            // con errore non significativo (Network Error)
            // mentre l'errore vero ERR_UPLOAD_FILE_CHANGED
            // viene restituito dopo in modo non catchabile
            // Resettiamo file dopo ogni upload
            if(uploadTarget)
              uploadTarget.value = null
            setUploadInfo(null)
        })
    }

    const recuperaPazienti = () => {
        if(formatAC === null) {
            appAlert(t("Formato file")+": "+t("campo obbligatorio"))
            return
        }
        setDisabledButs(true)
        let format = formatAC.value
        if (format === "video")
            format = 'JSON'
        const formData = new FormData()
        formData.append('centerCode', props.centro)
        formData.append('format', format)
        formData.append('StudyCode', props.studioId)
        // xxx
        formData.append('lang', getLang(i18n.language))
        const url = "/getStudyPatients"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            const fileName = 'studio_'+props.studioId+'_pazienti.'+format.toLowerCase()
            const res = check_and_download(response,format,formatAC.value,fileName,1)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (formatAC.value === "video") {
                setRows(addIdToRows(res.rows))
                setShowdatagrid(true)
            }
        })
        .catch(function (error) {
            setDisabledButs(false)
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }

    function back2recupera() {
        setMode("recupera")
        setShowdatagrid(false)
        setResult("")
    }

    return (
      <div>
        <Toolbar className="rowreverse">
            { mode==="recupera" ?
                <Button id="aggiorna" disabled={disabledButs} variant="contained" color="primary" onClick={() => setMode("aggiorna")} className="marginr10" > {t("Carica file stato pazienti")} </Button>
            :
                <Button id="recupera1" disabled={disabledButs} variant="contained" color="primary" onClick={back2recupera} > {t("Recupera pazienti")} </Button>
            }
        </Toolbar>
        { mode==="recupera" ?
            <div>
              <Container component="main" maxWidth="sm">
                <h2> {showdatagrid ? t("Aggiorna stato pazienti") : t("Recupera stato pazienti")} </h2>
                { showdatagrid ?
                    null
                :
                  <div className="blackColor">
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <MostAutocomplete id="formatAC1" name="formatAC" options={options_formatofile_download_pazienti_t} label={t("Formato file")+" *"} value={formatAC} onChange={handleFormatACChange} />
                        </Grid>
                        <Grid item xs={8}>
                            <MostButton id="recupera" onClick={recuperaPazienti} disabled={disabledButs}  label={t("Recupera")} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth className="margin2416" onClick={() => history.push(props.backUrl)} disabled={disabledButs}  label={t("Indietro")} />
                        </Grid>
                    </Grid>
                  </div>
                }
              </Container>
                { showdatagrid ?
                    <div className="blackColor margintop30">
                      { rows.length ?
                        <form onSubmit={handleSubmit(onSubmitUpdate)} noValidate>
                        <table className="borderTable marginAuto">
                            <thead>
                            <tr>
                              <th>{t("Da inserire")}</th>
                              <th>{t("Codice")}</th>
                              <th>{t("Cognome")}</th>
                              <th>{t("Nome")}</th>
                              <th>{t("Stato")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((item,itemIndex)=>{
                              return (
                                  <tr key={"tr"+item.center_id}>
                                      <td className="checkCentered">
                                          {item.study_patient_status === "ConvalidaDaCentro" ?
                                              <MostAutocomplete options={options_si_no_t} name={item.center_id} control={control} size="small" />
                                          : null }
                                      </td>
                                      <td>{item.center_id}</td>
                                      <td>{item.surname}</td>
                                      <td>{item.name}</td>
                                      <td>{item.study_patient_status}</td>
                                  </tr>
                              )
                            })}
                            </tbody>
                        </table>
                        <Container maxWidth="sm">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={8}>
                                <MostSubmitButton disabled={disabledButs} id="submit-button1" className="margintop30" label={t("Aggiorna")} />
                            </Grid>
                            <Grid item xs={4}>
                                <MostButton2 fullWidth className="margin2416" onClick={back2recupera} disabled={disabledButs}  label={t("Indietro")} />
                            </Grid>
                        </Grid>
                        </Container>
                        </form>
                      :
                        <Container maxWidth="sm">
                            <div className="tcenter"><i>{t("Nessun paziente inserito nello studio")}</i></div>
                        </Container>
                      }
                    </div>
                : null }
            </div>
        :
            <div>
              <Container component="main" maxWidth="sm">
                <h2> {t("Carica file stato pazienti")} </h2>
                <form onSubmit={handleSubmit(onSubmitUpload)} noValidate>
                <Grid container spacing={1} alignItems="center" className="blackColor">
                  { mode !== "getConsents" ?
                    <Grid item xs={12}>
                        <S3Field label={t("File da caricare")} s3_id={null} showError={appAlert} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} />
                    </Grid>
                  : null }
                    <Grid item xs={12}>
                        <MostAutocomplete id="formatAC" control={control} name="format" options={mode === "getConsents" ? options_formatofile_download_t : options_formatofile} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={8}>
                        <MostSubmitButton id="submit-button" disabled={disabledButs} className="b_loginok" label={t("Carica")} />
                    </Grid>
                    <Grid item xs={4}>
                        <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={() => history.push(props.backUrl)} label={t("Indietro")} />
                    </Grid>
                </Grid>
                </form>
              </Container>
            </div>
        }
        <Container component="main" maxWidth="sm">
          <Grid container spacing={1} alignItems="center" className="blackColor">
              <Grid item xs={8} className="tcenter">
              { loading ? (
                  <b>{t("Operazione in corso")}</b>
              ) : null }
              <PropagateLoader color={window.__most.palette.primary.darkColor} css={spinnerCss} loading={loading} />
              </Grid>
              <Grid item xs={4}>
              </Grid>
          </Grid>
        </Container>
        <div className="blackColor margin20all">
          <div dangerouslySetInnerHTML={{__html: result}} />
        </div>
      </div>
    )
}
export const StudioGestionePazienti = React.memo(StudioGestionePazientiInternal)

