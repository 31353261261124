import React, { useEffect, useState, useCallback, } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import Container from "@material-ui/core/Container";
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { getOptionListElement,Loading,MostSubmitButton,MostTextField,MostAutocomplete,MostButton2, } from "../components/MostComponents";
import { useForm } from "react-hook-form";
import MyAxios, { check_response } from "../MyAxios";
import { useHistory } from "react-router-dom";

// https://codesandbox.io/s/6j1760jkjk?file=/src/index.js:148-1742

const Question = (props) => {
    const { t } = useTranslation()
    const [qt, setQt] = useState(null)
    const typeChange = (e,el) => {
        setQt(el)
    }

    useEffect(() => {
        if(!props.row)
            return
        setQt(props.defaults[props.index].tipo)
    }, [props.row,props.defaults,props.index,])

    let def_r = []
    for (let i=0; i<6; i++) {
        let def = null
        if(props.row) {
            if(props.defaults[props.index].tipo.value === "radio") {
                if(props.row.points[props.index].risposte[i])
                    def = props.row.points[props.index].risposte[i].label
            } else {
                // list: il testo delle risposte e' ripetuto in ogni sottodomanda
                if(props.row.points[props.index].risposte[0][i])
                    def = props.row.points[props.index].risposte[0][i].label
            }
        }
        def_r.push(def)
    }
    log.debug("props",props)
    return (
        <div>
            <div style={{float: "right"}}>
                <MostButton2 fullWidth={false} onClick={props.toDelete} label={t("Rimuovi domanda")} />
            </div>
            <MostAutocomplete name={`${props.fieldName}.tipo_domanda`} control={props.control} options={props.options_tipodomanda} label={t("Tipo domanda")+" *"} defaultValue={props.row ? props.defaults[props.index].tipo : null} onChange={typeChange} />
            { qt ?
                <div>
                    <b>{t("Domanda")}</b><br/>
                    <MostTextField name={`${props.fieldName}.testo`} register={props.register} label={t("Testo")+" *"} defaultValue={props.row ? props.row.points[props.index].testo : null} multiline rows={4} />
                    <MostTextField name={`${props.fieldName}.help`} register={props.register} label="Help" defaultValue={props.row ? props.row.points[props.index].help : null}  />
                    <b>{t("Risposte")}</b><br/>
                    <MostTextField name={`${props.fieldName}.r0`} register={props.register} label={t("Risposta")+" 1 *"} defaultValue={def_r[0]}  />
                    <MostTextField name={`${props.fieldName}.r1`} register={props.register} label={t("Risposta")+" 2 *"} defaultValue={def_r[1]}  />
                    <MostTextField name={`${props.fieldName}.r2`} register={props.register} label={t("Risposta")+" 3"} defaultValue={def_r[2]}  />
                    <MostTextField name={`${props.fieldName}.r3`} register={props.register} label={t("Risposta")+" 4"} defaultValue={def_r[3]}  />
                    <MostTextField name={`${props.fieldName}.r4`} register={props.register} label={t("Risposta")+" 5"} defaultValue={def_r[4]}  />
                    <MostTextField name={`${props.fieldName}.r5`} register={props.register} label={t("Risposta")+" 6"} defaultValue={def_r[5]}  />
                    { qt.value === "radio" ?
                        <React.Fragment>
                        <b>{t("Valutazione risposte")}</b><br/>
                        <MostAutocomplete name={`${props.fieldName}.val_risp`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" *"} defaultValue={props.row ? props.defaults[props.index].val_risp : null} />
                        </React.Fragment>
                    : // list
                        <div>
                            <b>{t("Sottodomande")}</b><br/>
                            <MostTextField name={`${props.fieldName}.sd0`} register={props.register} label={t("Sottodomanda")+" 1 *"} defaultValue={props.row ? props.row.points[props.index].domande[0]: null}  />
                            <MostAutocomplete name={`${props.fieldName}.val_sd0`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" 1 *"} defaultValue={props.row ? props.defaults[props.index].val_risp[0] : null} />
                            <MostTextField name={`${props.fieldName}.sd1`} register={props.register} label={t("Sottodomanda")+" 2 *"} defaultValue={props.row ? props.row.points[props.index].domande[1] : null}/>
                            <MostAutocomplete name={`${props.fieldName}.val_sd1`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" 2 *"} defaultValue={props.row ? props.defaults[props.index].val_risp[1] : null} />
                            <MostTextField name={`${props.fieldName}.sd2`} register={props.register} label={t("Sottodomanda")+" 3"} defaultValue={props.row ? props.row.points[props.index].domande[2] : null}/>
                            <MostAutocomplete name={`${props.fieldName}.val_sd2`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" 3"} defaultValue={props.row ? props.defaults[props.index].val_risp[2] : null} />
                            <MostTextField name={`${props.fieldName}.sd3`} register={props.register} label={t("Sottodomanda")+" 4"} defaultValue={props.row ? props.row.points[props.index].domande[3] : null}/>
                            <MostAutocomplete name={`${props.fieldName}.val_sd3`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" 4"} defaultValue={props.row ? props.defaults[props.index].val_risp[3] : null} />
                            <MostTextField name={`${props.fieldName}.sd4`} register={props.register} label={t("Sottodomanda")+" 5"} defaultValue={props.row ? props.row.points[props.index].domande[4] : null}/>
                            <MostAutocomplete name={`${props.fieldName}.val_sd4`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" 5"} defaultValue={props.row ? props.defaults[props.index].val_risp[4] : null} />
                            <MostTextField name={`${props.fieldName}.sd5`} register={props.register} label={t("Sottodomanda")+" 6"} defaultValue={props.row ? props.row.points[props.index].domande[5] : null}/>
                            <MostAutocomplete name={`${props.fieldName}.val_sd5`} control={props.control} options={props.options_valutazione} label={t("Valutazione risposte")+" 6"} defaultValue={props.row ? props.defaults[props.index].val_risp[5] : null} />
                        </div>
                    }
                </div>
            : null }
        </div>
    )
}

export const Questionario = (props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const [indexes, setIndexes] = React.useState([])
  const [counter, setCounter] = React.useState(0)
  const [defaults, setDefaults] = useState(null)
  const { control, register, handleSubmit, errors } = useForm()
  const [disabledButs, setDisabledButs] = useState(false)
  const [options_tipodomanda] = useState([
        { label:t("domanda singola"), value:"radio"},
        { label:t("domanda multipla"), value:"list"},
  ])
  const [options_valutazione] = useState([
        { label:t("Prima risposta è la peggiore"), value:"min2max"},
        { label:t("Prima risposta è la migliore"), value:"max2min"},
  ])
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  //const qId = props.qId
  //log.debug("props.row",props.row)

  useEffect(() => {
    if(!props.row)
        return
    const c = props.row.points.length
    const is = []
    const def = []
    for (let i=0; i<c; i++) {
        is.push(i)
        let val_risp = null
        const tipo = getOptionListElement(options_tipodomanda,props.row.points[i].tipo)
        if(tipo) {
            if(tipo.value === "radio") {
                if(props.row.points[i].risposte[0].value === 0)
                    val_risp = getOptionListElement(options_valutazione,"min2max")
                else
                    val_risp = getOptionListElement(options_valutazione,"max2min")
            } else { // list
                val_risp = []
                for (let j=0; j<c; j++) {
                    if(props.row.points[i].risposte[j]) {
                        if(props.row.points[i].risposte[j][0].value === 0)
                            val_risp[j] = getOptionListElement(options_valutazione,"min2max")
                        else
                            val_risp[j] = getOptionListElement(options_valutazione,"max2min")
                    }
                }
            }
        }
        def.push({
            tipo: tipo,
            val_risp: val_risp
        })
    }
    log.debug("def",def)
    setIndexes(is)
    setCounter(c)
    setDefaults(def)
  }, [props.row,options_tipodomanda,options_valutazione,])

  function getValoriRisposte(n_riposte,val_risp) {
    let start, incr
    if(val_risp === "min2max") {
        start = 0
        incr = 1
    } else {
        start = n_riposte-1
        incr = -1
    }
    const risposte = []
    let val = start
    for (let j=0; j<n_riposte; j++) {
        risposte.push(val)
        val = val + incr
    }
    return risposte
  }

  const onSubmit = data => {
    console.log("data",data)
    console.log("indexes",indexes)
    console.log("counter",counter)
    const formq = data.domande
    const points=[]
    for (let i in formq) {
        if(!formq[i].tipo_domanda 
        || !formq[i].testo.trim().length
        || !formq[i].r0.trim().length
        || !formq[i].r1.trim().length
        || (formq[i].tipo_domanda.value === "radio" && 
            !formq[i].val_risp)
        || (formq[i].tipo_domanda.value === "list" && 
            (!formq[i].sd0.trim().length
            || !formq[i].sd1.trim().length
            || !formq[i].val_sd0
            || !formq[i].val_sd1))
        ) {
            appAlert(t("Domanda")+" "+(parseInt(i)+1)+" "+t("non completa"))
            return
        }
        let n_riposte=0
        const testo_risposte=[]
        for (; n_riposte<6; n_riposte++) {
            if(!formq[i]['r'+n_riposte].trim().length)
                break
            testo_risposte.push(formq[i]['r'+n_riposte].trim())
        }
        log.debug("n_riposte",n_riposte)
        let valori_risposte
        let n_sottodomande=0
        const sotto_domande=[]
        if(formq[i].tipo_domanda.value === "radio") {
            valori_risposte = getValoriRisposte(n_riposte,formq[i].val_risp.value)
        } else {
            for (; n_sottodomande<6; n_sottodomande++) {
                if(!formq[i]['sd'+n_sottodomande].trim().length
                || !formq[i]['val_sd'+n_sottodomande])
                    break
                sotto_domande.push(formq[i]['sd'+n_sottodomande].trim())
            }
            log.debug("n_sottodomande",n_sottodomande)
            valori_risposte = []
            for (let j=0; j<n_sottodomande; j++) {
                const r = getValoriRisposte(n_riposte,formq[i]['val_sd'+j].value)
                valori_risposte.push(r)
            }
        }

        const p = {
            testo: formq[i].testo.trim(),
            help: formq[i].help.trim(),
            tipo: formq[i].tipo_domanda.value,
            valori_risposte: valori_risposte,
            testo_risposte: testo_risposte,
        }
        if(n_sottodomande)
            p.domande = sotto_domande
        points.push(p)
        //log.debug("p",p)
    }
    if(!points.length) {
        appAlert(t("Nessuna domanda inserita"))
        return
    }
    //log.debug("points",points)
        let action
    let id = null
    if (props.qId === "__new__") {
        action = 'ADD'
        id = data.id
    } else {
        action = 'CHANGE'
        id = props.qId
    }
    const jdata = {
        action: action,
        id: id,
        name: data.name,
        description: data.description,
        points: points,
    }
    if(action === "CHANGE") {
        appAlert("Operazione non disponibile")
        return
    }
    MyAxios.post("/sf36", jdata)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            history.push('/questionari')
        } else {
            log.error("Error: "+res.error)
            appAlert(res.error)
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error)
        appAlert(error.message?error.message:JSON.stringify(error))
        setDisabledButs(false)
    })
  }

  const addDomanda = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter])
    setCounter(prevCounter => prevCounter + 1)
  }

  const removeDomanda = index => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)])
    setCounter(prevCounter => prevCounter - 1)
  }

  /*
  const clearDomande = () => {
    setIndexes([])
  }
  */

  let title
  if (props.qId === "__new__") {
    title = t("Nuovo questionario")
  } else {
    if (!props.row) {
        return <Loading />
    }
    title = t("Gestione questionario")+" "+props.row.name
  }

    return (
  <div className="app-container">
    <Header backMode="/questionari" title={title} />
    <div className="content-container contentWithToolbar">
    <Container component="main" maxWidth="md" className="blackColor">
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="formToolbar">
                <MostSubmitButton fullWidth={false} disabled={disabledButs || props.qId !== "__new__"} label={props.qId === "__new__" ? t("Inserisci") : t("Modifica")} />
                <MostButton2 fullWidth={false} disabled={disabledButs} className="margin2416" onClick={() => history.push('/questionari')} label={t("Indietro")} />
            </div>
      <MostTextField name="id" required={true} label={t("Codice")} register={register({ required: true })} defaultValue={props.row ? props.row.id : null} errors={errors} />
      <MostTextField name="name" required={true} label={t("Nome")} register={register({ required: true })} defaultValue={props.row ? props.row.name : null} errors={errors} />
      <MostTextField name="description" required={true} label={t("Descrizione")} register={register({ required: true })} defaultValue={props.row ? props.row.description : null} errors={errors} />
      <h3>{t("Questionario")}</h3>
      {indexes.map(index => {
        const fieldName = `domande[${index}]`
        return (
          <fieldset name={fieldName} key={fieldName}>
            <Question index={index} control={control} register={register} fieldName={fieldName} toDelete={removeDomanda(index)} row={props.row} options_tipodomanda={options_tipodomanda} options_valutazione={options_valutazione} defaults={defaults} />
          </fieldset>
        )
      })}

      <br />
      <MostButton2 fullWidth={false} onClick={addDomanda} label={t("Aggiungi domanda")} />
      {/* <button type="button" onClick={clearDomande}> Azzera Domande </button> */}
    </form>
    </Container>
    </div>
    <Footer />
  </div>
    )
}
