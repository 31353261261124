import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import { useHistory } from "react-router-dom";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Filtro } from './Filtro';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { DataTable } from '../components/DataTable';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { authorized, roleString } from '../UtilsInthem'
import SettingsIcon from '@material-ui/icons/Settings';
import MyAxios, {check_response} from "../MyAxios";
import { Loading, } from "../components/MostComponents";

export const Filtri = (props) => {
    const history = useHistory()
    const { userInfo } = useGlobalHook('userStore');
    const [medical_field_category, setMedical_field_category] = useState([])
    const [personal_fields, setPersonal_fields] = useState([])
    const [rows, setRows] = useState([])
    const [row, setRow] = useState(null)
    const [options_medical, setOptions_medical] = useState([])
    const [options_personal, setOptions_personal] = useState([])
    const [ready, setReady] = useState(false)
    const { t } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    // props.mode.endsWith("filtro")
    const filterId = props.match.params.filterId

    const rstatus_formatter = (params: ValueFormatterParams) => t(params.value)

    const goToDetail = (id) => {
        if(props.mode==="ga_filtri")
            history.push("/ga_filtro/"+id)
        else if(props.mode==="ca_filtri")
            history.push("/ca_filtro/"+id)
        else
            history.push("/dm_filtro/"+id)
    }

    const columns = [

      { field: 'bottoni', headerName: ' ', width: 80, renderCell: (params: GridCellParams) => {
          const onClickSettings = () => {
            const id = params.id
            history.push(goToDetail(id))
          }
          return (
            <div>
            <Tooltip title={t("Gestione filtro")}><IconButton className="gold" onClick={onClickSettings}><SettingsIcon /></IconButton></Tooltip>
            </div>
          )
        }
      },
      // { field: 'id', headerName: 'Codice', },
      { field: 'name', headerName: t('Nome'), width: 200 },
      { field: 'description', headerName: t('Descrizione'), minWidth: 200, flex: 1 },
      { field: 'geotree', headerName: t('Area'), width: 200, },
      { field: 'rstatus', headerName: t('Status'), width: 120, valueGetter: rstatus_formatter },
    ];

    useEffect(() => {
        function compareRows( a, b ) {
            if ( a.name < b.name )
                return -1;
            if ( a.name > b.name )
                return 1;
            return 0;
        }
        function compareMedical_field_category( a, b ) {
            const aname = Object.keys(a)[0]
            const bname = Object.keys(b)[0]
            if ( aname < bname )
                return -1;
            if ( aname > bname )
                return 1;
            return 0;
        }
        const jdata = {
            action: 'LIST',
            as_role: props.mode.startsWith("ca") ? "CenterAdmin" : (props.mode.startsWith("ga")? "GlobalAdmin" : "DataManager"),
        }
        MyAxios.post("/inthem_query", jdata)
        .then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
          // deve essere ordinato per nome categoria altrimenti ordine
          // non matcha cio' che ci arriva in row.field_list.MEDICAL_DATA
          // e succede un macello nella costruzione dell'interfaccia di modifica filtro
          data.medical_field_category.sort ( compareMedical_field_category )
          setMedical_field_category(data.medical_field_category)
          const dm = data.medical_field_category
          const om = []
          for(let i in dm) {
            const group = Object.keys(dm[i])[0]
            for(let j in dm[i][group]) {
                om.push({label:group+" "+dm[i][group][j],value:dm[i][group][j]})
            }
          }
          setOptions_medical(om)
          setPersonal_fields(data.personal_fields)
          const dp = data.personal_fields
          const op = []
          // quali campi non possono essere utilizzati nei filtri?
          // questi sono tutti i campi anagrafici:
          // center_code center_id center_start dob yob age sex dead_cause dead_date lob_geotree address_geotree name surname legal_id telephone mobile mobile_prefix email address_nation address_area address_town address_street address_zip lob_nation lob_region
          // togliamo quelli crittografati + center_id
          const not4filters = [ "name","surname","legal_id","telephone","mobile","mobile_prefix","email","address_town","address_street","dob","dead_date","center_id", ]
          for(let i in dp) {
            if(!not4filters.includes(dp[i]))
                op.push({label:dp[i],value:dp[i]})
          }
          setOptions_personal(op)
          data.rows.sort( compareRows )
          setRows(data.rows)
          if(filterId && filterId !== "__new__") {
            let found = 0
            for (let i in data.rows) {
                if(data.rows[i].id === filterId) {
                    setRow(data.rows[i])
                    found = 1
                    break
                }
            }
            if(!found)
                history.push("/uhome")
          }
          setReady(true)
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,filterId,props.mode,history,])

    const nuovo = (el) => {
        history.push(goToDetail("__new__"))
    }

    const redirect = authorized(
        props.mode.startsWith("ga") ?  "GlobalAdmin" : (props.mode.startsWith("ca") ? "CenterAdmin" : "DataManager"),
        userInfo)
    if(redirect)
        return redirect

    if(!ready)
        return <Loading />

    if(props.mode.endsWith("filtro")) {
        if(filterId !== "__new__" && !row)
            return <Loading />
        //log.debug("Filtri")
        return (
            <Filtro mode={props.mode} row={row} filterId={filterId} medical_field_category={medical_field_category} personal_fields={personal_fields}
                options_medical={options_medical} options_personal={options_personal} 
            />
        )
    }

    return (
      <div className="app-container">
        <Header title={t("Gestione filtri")} subtitle={roleString(props.mode,t)} />
        <div className="content-container">
            <DataTable columns={columns} rows={rows} density={"compact"} toolbar={true} tbButton={t("Nuovo filtro")} tbButtonOnClick={nuovo} disableSelectionOnClick={true} />
{/*
vedi 2.3.8 capitolato: Filtri
- numeri e date: previsto operatore between (si possono usare >= e <=)
? campi di tipo ICD (quali? diagnosi e' specific_diagnosis che ha campo icd): like "x%" implicito, 425 deve comprendere 425.7
*/}
        </div>
        <Footer />
      </div>
    )
}
