import React from "react";
import log from 'loglevel';
import { isMobile } from "../Utils";
import { Native } from "./Native";
import { Controller } from "react-hook-form";
import { useGlobalHook } from '@devhammed/use-global-hook'
import Select from "react-select";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from "@material-ui/core/TextField";
import useStyles from "./useStyles";
import { FileUpload } from "./FileUpload";
import { getLang } from "../i18n";
import { get_document } from "../MyAxios";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import WarningIconMU from '@material-ui/icons/Warning';
import { useTranslation } from "react-i18next";
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import itLocale from "date-fns/locale/it";
import frLocale from "date-fns/locale/fr";
import ptLocale from "date-fns/locale/pt";
import enLocale from "date-fns/locale/en-GB";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from "@material-ui/core/Tooltip";
//import blueGrey from "@material-ui/core/colors/blueGrey";

// Componenti JSX con richiamo semplificato. 

export const MostFab = ({
    icon = 'ArrowBackIcon',
    onClick,
    tooltip
}) => {
  const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(2),
  },
}));
  const classes = useStyles();
  return (
    <Tooltip title={tooltip}>
        <Fab className={classes.fab} color="primary" onClick={onClick}>
            { icon === 'ArrowBackIcon' ?
                <ArrowBackIcon />
            : null }
        </Fab>
    </Tooltip>
  )
}

export const MostRadioGroup = ({ 
  control,
  rules,
  name,
  disabled,
  defaultValue=null, // attenzione, valori devono essere stringhe
  options = [], // attenzione, valori vengono forzati a stringhe
  errors,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  if (!control)
    return (
        <RadioGroup 
            name={name}
            value={value}
            onChange={onChange}
        >
        {options.map((option,index)=>{
            return (
                <FormControlLabel control={<Radio />} key={"radio"+option.value} value={""+option.value} label={option.label} />
            )
        })}
        </RadioGroup>
    )
  return (
    <FormControl component="fieldset" disabled={disabled} >
      <Controller
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        as={
            <RadioGroup>
            {options.map((option,index)=>{
                return (
                    <FormControlLabel control={<Radio />} key={"radio"+option.value} value={""+option.value} label={option.label} />
                )
            })}
            </RadioGroup>
        }
      />
      {errors && errors[name] && <div className='formFieldError'>{t('campo obbligatorio')}</div>}
    </FormControl>
  )
}

export const MostTimePicker = ({ 
  disabled,
  inputVariant="outlined",
  size="small",
  fullWidth=false,
  label,
  value,
  onChange,
  onError,
  margin="dense", // 'dense' | 'none' | 'normal'
  initialFocusedDate="2021-01-01 08:00",    // contano solo le ore
}) => {
  const { i18n, t } = useTranslation()
  const lang = getLang(i18n.language)
  const invalidDateMessage=t("Ora non valida")
  const minDateMessage=t("Data precedente al minimo previsto")
  const maxDateMessage=t("Data successiva al massimo previsto")
  const cancelLabel=t("Annulla")
  const clearLabel=t("Azzera")
  let loc
  if (lang === "it")
    loc = itLocale
  else if (lang === "fr")
    loc = frLocale
  else if (lang === "pt")
    loc = ptLocale
  else 
    loc = enLocale
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={loc} >
        <KeyboardTimePicker
          clearable
          ampm={false}
          mask="__:__"
          disabled={disabled}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          invalidDateMessage={invalidDateMessage}
          cancelLabel={cancelLabel}
          clearLabel={clearLabel}
          inputVariant={inputVariant}
          size={size}
          fullWidth={fullWidth}
          margin={margin}
          label={label}
          value={value}
          onChange={onChange}   // {date => func(date)} (ma occhio a isNaN)
          onError={onError}
          initialFocusedDate={initialFocusedDate}
        />
    </MuiPickersUtilsProvider>
  )
}

// NOTA: default per data di nascita (prima scelta anno e vietate date future)
export const MostDatePicker = ({ 
  disabled,
  disableToolbar=true,
  disableFuture=true,
  inputVariant="outlined",
  size="small",
  fullWidth=false,
  openTo="year",  // "date" | "year" | "month"
  format="dd/MM/yyyy",
  label,
  value,
  onChange,
  onError,
  margin="dense", // 'dense' | 'none' | 'normal'
}) => {
  const { i18n, t } = useTranslation()
  const lang = getLang(i18n.language)
  const invalidDateMessage=t("Data non valida")
  const minDateMessage=t("Data precedente al minimo previsto")
  const maxDateMessage=t("Data successiva al massimo previsto")
  const cancelLabel=t("Annulla")
  const clearLabel=t("Azzera")
  let loc
  if (lang === "it")
    loc = itLocale
  else if (lang === "fr")
    loc = frLocale
  else if (lang === "pt")
    loc = ptLocale
  else
    loc = enLocale

  const materialTheme = createMuiTheme({
    palette: {
          primary: window.__most.palette.secondary
      },
  })
  /*
  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: blueGrey.A200,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: blueGrey.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        day: {
          color: blueGrey.A700,
        },
        daySelected: {
          backgroundColor: blueGrey["400"],
        },
        dayDisabled: {
          color: blueGrey["100"],
        },
        current: {
          color: blueGrey["900"],
        },
      },
      // non funziona
      MuiPickersModal: {
        dialogAction: {
          color: blueGrey["400"],
        },
      },
    },
  })
  */

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={loc} >
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          disabled={disabled}
          disableToolbar={disableToolbar}
          disableFuture={disableFuture}
          inputVariant={inputVariant}
          minDateMessage={minDateMessage}
          maxDateMessage={maxDateMessage}
          invalidDateMessage={invalidDateMessage}
          cancelLabel={cancelLabel}
          clearLabel={clearLabel}
          size={size}
          fullWidth={fullWidth}
          openTo={openTo}
          format={format}
          margin={margin}
          label={label}
          value={value}
          onChange={onChange}
          onError={onError}
          /*
          KeyboardButtonProps={{
            'aria-label': 'cambia data',
          }}
          */
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export const MostTextField = ({ 
  name, 
  label, 
  defaultValue,
  autoComplete, 
  autoFocus=false, 
  fullWidth=true, 
  multiline,
  rows,
  margin="dense",
  onChange,
  type="text", 
  variant="outlined",
  //variant,
  required,     // serve per fare aggiungere * al campo obbligatorio, oppure se si usano controlli del browser togliendo noValidate dalla form
  InputProps,
  inputProps,
  InputLabelProps,
  value,
  disabled=false,
  register,     // se si vuole avere required gestito da form react bisogna passare register={register({ required: true })}
  errors,
  helperText,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
    <TextField
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      InputLabelProps={InputLabelProps}
      multiline={multiline}
      rows={rows}
      onChange={onChange}
      defaultValue={defaultValue}
      variant={variant}
      margin={margin}
      size="small"
      required={required}
      fullWidth={fullWidth}
      name={name}
      label={label}
      type={type}
      id={name}
      inputRef={register}
      InputProps={InputProps}
      inputProps={inputProps}
      value={value}
      disabled={disabled}
      helperText={helperText}
    />
    {errors && errors[name] && <div className='formFieldError'>{errors[name].type === 'required' ? t('campo obbligatorio') : errors[name].type === 'min' ? t('form errors min') : errors[name].type === 'max' ? t('form errors max') : 'Error '+errors[name].type }</div>}
    </React.Fragment>
  )
};

// https://stackoverflow.com/questions/61655199/proper-way-to-use-react-hook-form-controller-with-material-ui-autocomplete
// https://stackoverflow.com/questions/61219164/material-ui-autocomplete-default-value-of-empty-string
export const MostAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel = (option) => option && option.label ? option.label : "",
  getOptionSelected=(option, value) => {
        if (!value || value.value === "")
          return true
        if (value.value === option.value)
          return true
      },
  onChange,
  disabled=false,
  control,
  name,
  id=null,
  defaultValue=null,
  label,
  value,
  renderOption,
  rules,      // rules={{ required: true }} per rendere obbligatorio (form), comunque bisogna aggiungere " *" a mano
  errors,
  size,
  style,
  multiple,
  disableClearable,
  selectOnMobile,
}) => {
  const { t } = useTranslation();

  if(selectOnMobile && isMobile()) {
    // qua si lavora con solo valori e non oggetti
    if(value)
        value = value.value
    if(defaultValue)
        defaultValue = defaultValue.value
    if(!control) {
        // onChange fa in modo da restituire oggetto value/label
        return (
            <Native 
                    name={name}
                    label={label}
                    options={options}
                    value={value} 
                    disabled={disabled}
                    onChange={(e) => {
                        if(onChange) {
                            if(e.target.value)
                                onChange(null,getOptionListElement(options,e.target.value))
                            else
                                onChange(null,null)
                        }
                    }} 
               />
        )
    }
    // NOTA: esternamente si lavora su oggetti label/value qui solo value
    //       form react restituisce solo il value
    log.debug("NOTA: su mobile form restituisce solo il valore e NON l'oggetto label/value, name",name)
    return (
    <React.Fragment>
      <Controller 
        render={(
            { onChange, onBlur, value, name, ref }
          ) => (
            <Native
                name={name}
                label={label}
                options={options} 
                disabled={disabled}
                value={value} 
                onChange={(e) => {
                    onChange(e)
                }} 
            />
        )}
        name={name} 
        value={value} 
        control={control} 
        rules={rules} 
        defaultValue={defaultValue}
        />
      {errors && errors[name] && <div className='formFieldError'>{t('campo obbligatorio')}</div>}
    </React.Fragment>
    )
  }

  if(multiple && !defaultValue)
    // con multiple si aspetta un vettore
    defaultValue=[]
  if (!control)
    return (
        <Autocomplete
          name={name}
          id={id}
          options={options}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          renderOption={renderOption}
          style={style}
          multiple={multiple}
          size={size}
          defaultValue={defaultValue}
          disableClearable={disableClearable}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField {...params} label={label} margin="dense" variant="outlined" />
          )}
        />
    )
  const customOnChange = onChange
  // xxx nota: onChange ma non c'e' value !
  return (
    <React.Fragment>
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          id={id}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          disabled={disabled}
          disableClearable={disableClearable}
          multiple={multiple}
          // aggiunto per gestione multiple. controllare che non abbia controindicazioni nel caso normale
          defaultValue={defaultValue}
          renderOption={renderOption}
          onChange={(e, data) => {
            // onChange senza control restituisce (e,el)
            if (customOnChange) customOnChange(null,data)
            return onChange(data)
          }} 
          renderInput={(params) => (
            <TextField {...params} label={label} margin="dense" variant="outlined" />
          )}
          {...props}
        />
      )}
      // quale e' giusto?
      /*
      onChange={([, data]) => {
        if(onChange) onChange(data)
        return data
      }}
      */
      onChange={([, data]) => data }
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
    />
    {errors && errors[name] && <div className='formFieldError'>{t('campo obbligatorio')}</div>}
    </React.Fragment>
  );
}

export function capitalize(str) {
  return str.replace(/\b\w/g , function(m){ return m.toUpperCase(); } )
}

export const getOptionList = (list,value,label,dontsort,nameMode) => {
    let label1 = null
    let label2 = null
    if(label && label.indexOf("+") !== -1) {
        const parts = label.split("+")
        label1 = parts[0]
        label2 = parts[1]
    }
    const ret = []
    for (let i in list) {
        //if(ret.length === 0)
        //    ret.push({value: null, label: ""})
        if(!value)
            ret.push({value: list[i], label: list[i]})
        else if(label1) {
            let lab
            if(nameMode)
                lab = capitalize(list[i][label1]+" "+list[i][label2])
            else
                lab = list[i][label1]+" - "+list[i][label2]
            ret.push({value: list[i][value], label: lab, row: list[i]})
        } else
            ret.push({value: list[i][value], label: list[i][label], row: list[i]})
    }
    if(dontsort)
        return ret
    return ret.sort((a,b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0))
}

export const getOptionListElement = (list,value) => {
    for (let i in list) {
        if(list[i].value === value)
            return list[i]
    }
    return null
}

export const getOptionListLabel = (list,value) => {
    for (let i in list) {
        if(list[i].value === value)
            return list[i].label
    }
    return value
}

export const MostCheckbox = ({
  control,
  defaultChecked,
  label,
  name,
  register,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          inputRef={register}
          control={control}
          name={name}
          color="primary"
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
        />
      }
      className="MostCheckbox"
      label={label}
      disabled={disabled}
    />
  );
};

// se si vuole passare ref, bisogna usare un nome di prop diverso da ref (vedi fref)
// ??? anche forzando type="button" da richiamo in casi strani forza la form submit ???
// -> type fisso a submit e altra routine per bottone di tipo button
//  vedi MostButton() sotto
export const MostSubmitButton = ({
    label,
    onClick,
    id,
    fref,
    disabled=false,
    className,
    fullWidth=true,
    color="primary",
    size,
 }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} ref={fref} id={id} disabled={disabled} type="submit" fullWidth={fullWidth} variant="contained" color={color} className={`${classes.submit} ${className}`} size={size} >
      {label}
    </Button>
  );
};

export const MostButton = ({
    label,
    onClick,
    id,
    fref,
    disabled=false,
    className,
    fullWidth=true,
    color="primary",
    size,
 }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} ref={fref} id={id} disabled={disabled} type="button" fullWidth={fullWidth} variant="contained" color={color} className={`${classes.submit} ${className}`} size={size} >
      {label}
    </Button>
  );
};

export const MostButton2 = ({
    label,
    onClick,
    id,
    type="button",
    disabled=false,
    variant="outlined",
    color="secondary",
    variab,
    variab_value,
    fullWidth=false,
    className,
    size,
 }) => {
  if (variab !== undefined && variab === variab_value)
    return (
        <Button fullWidth={fullWidth} variant="contained" color="primary" onClick={onClick} type={type} className={className} id={id} size={size} >
        {label}
        </Button>
    );
  if (variab !== undefined && variab !== variab_value)
    return (
        <Button fullWidth={fullWidth} variant="outlined" color="secondary" onClick={onClick} type={type} className={className} id={id} size={size} >
        {label}
        </Button>
    );
  return (
    <Button fullWidth={fullWidth} onClick={onClick} disabled={disabled} type={type} variant={variant} color={color} className={className} id={id} size={size} >
      {label}
    </Button>
  );
};

export const selectValue2obj = (v,options) => {
    for (let i in options)
        if (options[i].value === v)
            return options[i]
    return ""
}

export const MostSelect = (
    { 
    name,
    options,
    control,
    onChange,               // xxx NON FUNZIONA
    required,   // serve SOLO se si usano controlli del browser togliendo noValidate dalla form, NON serve per fare aggiungere * al campo obbligatorio (bisogna usare altri tipi di select) -> aggiungere " *" a mano...
    rules,      // rules={{ required: true }} per rendere obbligatorio (form), comunque bisogna aggiungere " *" a mano
    className,
    placeholder,
    value,
    defaultValue="",        // funziona MA bisogna passare elemento delle opzioni e non solo il valore (vedi selectValue2obj()
    label ,
    errors,
  }) => {
  const { t } = useTranslation();
  if(!className)
    className = ""
  className += " MuiFormControl-marginDense blackColor"
  return (
    <React.Fragment>
    <Controller 
        className={className}
        as={Select}
        name={name} 
        value={value} 
        options={options} 
        control={control} 
        required={required} 
        rules={rules} 
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder} />
    {errors && errors[name] && <div className='formFieldError'>{t('campo obbligatorio')}</div>}
    </React.Fragment>
  );
  /*
  // onChange non funziona usando "as" (che è deprecato)
  // ma con render funziona quasi nulla... (non funziona setValue)
  const extOnChange = onChange
  const extValue = value
  return (
    <Controller 
        className={className}
        render={(
            { onChange, onBlur, value, name, ref }
          ) => (
            <Select
                placeholder={placeholder}
                required={required} 
                options={options} 
                value={extValue} 
                onChange={(e) => {
                    onChange(e)
                    if(extOnChange) extOnChange(e)
                }} 
            />
        )}
        name={name} 
        value={value} 
        control={control} 
        rules={rules} 
        defaultValue="" 
        // onFocus
        />
  );
  */
};

export const Timeout = (props) => {
  const { userInfo } = useGlobalHook('userStore');
  if (props.idleTimeout && userInfo.username) {
    log.debug("--- Idle timeout "+new Date())
    window.location.replace('/logout')
  }
  return null
}

export const NoMatch = () => {
    log.debug("--- NoMatch "+window.location.href)
    window.location.replace('/logout')
    return null
}

export const Check = (props) => {
    if (props.good)
        return <CheckIcon className="goodValue vertMiddle"/>
    return <ClearIcon className="badValue vertMiddle"/>
}

export const WarningIcon = () => (
    <WarningIconMU className="badValue vertMiddle"/>
)

export const Loading = () => {
    const { t } = useTranslation();
    return <div className="margin20"><p>{t("Loading")+"..."}</p></div>
}

// https://material-ui.com/components/tabs/
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const options_si_no = (t) => {
    return [
        {value: "1", label: t("Sì")},
        {value: "0", label: t("No")},
    ]
}

export const options_boolean = (t) => {
    return [
        {value: "true", label: t("Vero")},
        {value: "false", label: t("Falso")},
    ]
}

// a tutti gli effetti come options_boolean ma visualizzato Si/No invece di Vero/Falso
export const options_boolean_si_no = (t) => {
    return [
        {value: "true", label: t("Sì")},
        {value: "false", label: t("No")},
    ]
}

export const getOptionBooleanListElement = (list,value) => {
    if(value === null || value === undefined)
        return null
    if(value)
        return getOptionListElement(list,"true")
    return getOptionListElement(list,"false")
}

export const anyFormErrors = (errors) => {
    for (let i in errors)
        return true
    return false
}

export const S3Field = (props) => {
    const { t } = useTranslation();

    return (
        <div className="boxForm">
            <div className="formLabelColor" dangerouslySetInnerHTML={{__html: props.label}} />
            { props.readOnly && props.s3_id ?
                <Tooltip title={t("Download")}>
                  <IconButton
                    onClick={() => get_document(props.s3_id,props.showError)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
            : props.readOnly ?
                <div className="margin20">
                    {t("Nessun documento")}
                </div>
            : props.s3_id && !props.docRemoved ?
                <div>
                    <Tooltip title={t("Download")}>
                      <IconButton
                        onClick={() => get_document(props.s3_id,props.showError)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("Rimuovi")}>
                      <IconButton
                        onClick={() => props.setDocRemoved(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                </div>
            :
                <div className="fileupload">
                    <FileUpload
                        setUploadInfo={props.setUploadInfo}
                        setUploadTarget={props.setUploadTarget}
                    />
                </div>
            }
        </div>
    )
}

export const readOnlyProps = {
        style: {
            color: '#077',
            backgroundColor: '#f0f0f0',
        }
    }

export const notSavedProps = {
        style: {
            color: '#700',
            backgroundColor: '#f0f0f0',
        }
    }

