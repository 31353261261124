import React, { useCallback, useEffect, useState, } from "react";
import log from 'loglevel';
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, {check_response} from "../MyAxios";
import { MTabCampiCondizioni } from "./MTabCampiCondizioni";
import { MFiltroFormConditionDialog } from "./MFiltroFormConditionDialog";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Loading, MostButton2, MostSubmitButton, MostTextField, } from "../components/MostComponents";
import { ymd } from "../Utils";
import { myGeoArea } from "../UtilsInthem";

const FiltroForm = (props) => {
  //const [disabledButs, setDisabledButs] = useState(false)
  const disabledButs = false
  const { userInfo } = useGlobalHook('userStore');
  const { register, handleSubmit, errors, } = useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const [ready, setReady] = useState(false)

  const [condizioniAnagrafiche,setCondizioniAnagrafiche] = useState([])
  const [condizioniMediche,setCondizioniMediche] = useState([])

  const [sql_condition, setSql_condition] = useState("")
  const [checkedSimple, setCheckedSimple] = useState(true)
  const [defaultChecked, setDefaultChecked] = useState({})
  const [allPersonalAndMedical, setAllPersonalAndMedical] = useState(false)
  const [allPersonal, setAllPersonal] = useState(false)
  const [allMedical, setAllMedical] = useState([])
  const allPersonalAndMedicalOnChange= (e,val) => {
    setAllPersonalAndMedical(val)
  }
  const allPersonalOnChange = (e,val) => {
    setAllPersonal(val)
  }
  const allMedicalOnChange = (val,index) => {
    const v = Array.from(allMedical)
    v[index] = val
    setAllMedical(v)
  }

  // MFiltroFormConditionDialog
  const [openDialog, setOpenDialog] = useState(false)
  const [optionsDialog, setOptionsDialog] = useState(null)
  const [errorsDialog, setErrorsDialog] = useState(null)
  const [conditionfieldtype, setConditionfieldtype] = useState(null)
  const [valore_date, setValore_date] = useState(null)
  const [operatore, setOperatore] = useState(null)
  const [options_valore, setOptions_valore] = useState(null)
  const [options_operatore, setOptions_operatore] = useState([])
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  function resetAndOpenConditionDialog(type) {
    setOperatore(null)
    setOptions_valore(null)
    setOptions_operatore([])
    setOptionsDialog(type)
    setErrorsDialog("")
    setConditionfieldtype(null)
    setValore_date(null)
    setOpenDialog(true)
  }

  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
  }

  useEffect(() => {
    const v = []
    for (let i=0; i<props.medical_field_category.length; i++) {
        v.push(false)
    }
    setAllMedical(v)
    setReady(true)
  }, [props.medical_field_category])

  const handleSubmitDialog = (vals) => {
    log.debug(vals)
    setErrorsDialog("")
    let valore = null
    if(conditionfieldtype === "string") 
        valore = "'"+vals.valore_string.trim()+"'"
    else if(conditionfieldtype === "number") 
        valore = vals.valore_number
    else if(conditionfieldtype === "boolean") 
        valore = vals.valore_boolean ? vals.valore_boolean.value : null
    else if(conditionfieldtype === "list") {
        if(["in","not in"].includes(vals.operatore.value)) {
            valore = ''
            if (vals.valore_list_multi.length !== 0) {
                for (let i in vals.valore_list_multi) {
                    if(valore)
                        valore += ","
                    valore += "'"+vals.valore_list_multi[i].value+"'"
                }
                valore = "("+valore+")"
                //log.debug(vals.operatore.value,valore)
            }
        } else 
            valore = vals.valore_list ? "'"+vals.valore_list.value+"'" : null
    }
    else if(conditionfieldtype === "date") {
        if(isNaN(valore_date)) {
            setErrorsDialog(t("Data non valida"))
            return
        }
        valore = valore_date ? "'"+ymd(valore_date)+"'" : null
    }
    if(valore === null)
        valore = ""
    if(valore === "" && !vals.operatore.value.startsWith("is ")) {
        setErrorsDialog(t("Valore non inserito"))
        return
    }
    if(optionsDialog === "personal") {
        const v = Array.from(condizioniAnagrafiche)
        let max = -1
        for (let i in v)
            if (v[i].ID > max)
                max = v[i].ID
        max++
        v.push({ID:max, tipo:optionsDialog, campo:vals.campo.value, operatore:vals.operatore.value, valore:valore})
        setCondizioniAnagrafiche(v)
    } else {
        const v = Array.from(condizioniMediche)
        let max = -1
        for (let i in v)
            if (v[i].ID > max)
                max = v[i].ID
        max++
        v.push({ID:max, tipo:optionsDialog, campo:vals.campo.value, operatore:vals.operatore.value, valore:valore})
        setCondizioniMediche(v)
    }
    setOpenDialog(false)
  }

  const filtroOnSubmit = (vals) => {
    log.debug("filtroOnSubmit", vals)
    const patient_field_list = []
    if (allPersonal || allPersonalAndMedical)
        patient_field_list.push("ALL")
    else {
        for (let i in props.personal_fields) {
            const f = props.personal_fields[i]
            const chk = "chk_personal_"+f
            if (vals[chk])
                patient_field_list.push(f)
        }
    }
    const medical_data_field_list_obj = {}
    for (let i in props.medical_field_category) {
        const category = Object.keys(props.medical_field_category[i])[0]
        const v = medical_data_field_list_obj[category] = []
        if (allMedical[i] || allPersonalAndMedical) 
            v.push("ALL")
        else {
            const fields = props.medical_field_category[i][category]
            for (let i in fields) {
                const f = fields[i]
                const chk = "chk_medical_"+category+"_"+f
                if (vals[chk])
                    v.push(f)
            }
        }
    }
    let sql_condition = null
    let filter_list = null
    if(vals.sql_condition !== "") {
        sql_condition = vals.sql_condition
    } else {
        const patient_filter_list = []
        for (let i in condizioniAnagrafiche) {
            const c = condizioniAnagrafiche[i]
            patient_filter_list.push([c.campo,c.operatore,c.valore])
        }
        const medical_data_filter_list = []
        for (let i in condizioniMediche) {
            const c = condizioniMediche[i]
            medical_data_filter_list.push([c.campo,c.operatore,c.valore])
        }
        if(patient_filter_list.length || medical_data_filter_list.length)
            filter_list = {
                PATIENT: patient_filter_list,
                MEDICAL_DATA: medical_data_filter_list,
            }
    }
    let action
    let id
    if(props.modeFiltroForm === "updating") {
        action = 'MODIFY'
        id = props.row.id
    } else {
        action = 'ADD'
        id = null
    }
    const jdata = {
        action: action,
        as_role: props.mode.startsWith("ca") ? "CenterAdmin" : (props.mode.startsWith("ga") ? "GlobalAdmin" : "DataManager"),
        id: id,
        name: vals.name,
        description: vals.description,
        // xxxxx
        geotree: 'W',
        field_list: {
            PATIENT: patient_field_list,
            MEDICAL_DATA: medical_data_field_list_obj,
        },
        sql_condition: sql_condition,
        filter_list: filter_list,
    }
    MyAxios.post("/inthem_query", jdata)
    .then((response) => {
      const data = check_response(response);
      if(!data.success) {
        log.error(data.error)
        appAlert(data.error)
        return
      }
      history.push(props.backUrl)
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }

  function modificaQuery() {
    setReady(false)
    props.setModeFiltroForm("updating")
    const defaultChecked = {}
    const v = props.row.field_list.PATIENT
    if (v[0] === "ALL")
        setAllPersonal(true)
    else {
        setAllPersonal(false)
        for (let i in v)
            defaultChecked[v[i]] = true
    }
    const allMedical = []
    for (let category in props.row.field_list.MEDICAL_DATA) {
        const v = props.row.field_list.MEDICAL_DATA[category]
        if(v[0] === "ALL")
            allMedical.push(true)
        else {
            allMedical.push(false)
            for (let i in v)
                defaultChecked[v[i]] = true
        }
    }
    setDefaultChecked(defaultChecked)
    setAllMedical(allMedical)
    if(props.row.sql_condition) {
        setCheckedSimple(false)
        setSql_condition(props.row.sql_condition)
        setCondizioniAnagrafiche([])
        setCondizioniMediche([])
    } else {
        setCheckedSimple(true)
        setSql_condition("")
        const ca = []
        const cm = []
        if(props.row.filter_list) {
            for (let i in props.row.filter_list.PATIENT) {
                const el = props.row.filter_list.PATIENT[i]
                ca.push({ID:i, tipo:"personal", campo:el[0], operatore:el[1], valore:el[2]})
            }
            for (let i in props.row.filter_list.MEDICAL_DATA) {
                const el = props.row.filter_list.MEDICAL_DATA[i]
                cm.push({ID:i, tipo:"medical", campo:el[0], operatore:el[1], valore:el[2]})
            }
        }
        setCondizioniAnagrafiche(ca)
        setCondizioniMediche(cm)
    }
    setReady(true)
  }

  function back() {
    if(props.modeFiltroForm === "updating") {
        setReady(false)
        // fatti in sequenza rimane interfaccia che viene cancellata elemento per elemento
        // cosi' interfaccia vuota che viene poi costruita da zero
        setTimeout(()=>{
            props.setModeFiltroForm(null)
            setReady(true)
        },500)
    } else
        history.push(props.backUrl)
  }

  function suspendResume() {
    const jdata = {
        action: props.row.rstatus === "SUSPEND" ? "RESUME" : "SUSPEND",
        id: props.row.id,
        as_role: props.mode.startsWith("ca") ? "CenterAdmin" : (props.mode.startsWith("ga") ? "GlobalAdmin" : "DataManager"),
    }
    MyAxios.post("/inthem_query", jdata)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            appAlert2(t("Stato modificato"),() => {
                back()
            })
        } else {
            log.error("Error: "+res.error);
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }

  if(!ready) {
    return <Loading />
  }

  let disabledFields 
  if (props.filterId === "__new__" || props.modeFiltroForm === "updating")
    disabledFields=false
  else
    disabledFields=true

  log.debug("FiltroForm",props.row)
  return (
    <div>
    { props.filterId !== "__new__" && props.modeFiltroForm !== "updating" ? 
        <Toolbar className="rowreverse">
            <Button variant="outlined" color="secondary" className="marginr10" onClick={back} > {t("Indietro")} </Button>
            { myGeoArea(props.mode,userInfo) === props.row.geotree ? 
                <React.Fragment>
                    <Button variant="contained" color="primary" className="marginr10" onClick={suspendResume} > {props.row.rstatus === "SUSPEND" ? t("Riattiva") : t("Disabilita")} </Button>
                    {/*
                    <Button disabled variant="contained" color="primary" className="marginr10" > {t("Cancella")} </Button>
                    */}
                    <Button variant="contained" color="primary" className="marginr10" onClick={modificaQuery} > {t("Modifica")} </Button>
                </React.Fragment>
            :
                null
            }
        </Toolbar>
    : null }
    <div className="blackColor">
      <form key="filtroForm" id="filtroForm" onSubmit={handleSubmit(filtroOnSubmit)} noValidate>
        { props.filterId === "__new__" || props.modeFiltroForm === "updating" ?
            <div className={(props.filterId === "__new__"||props.mode==="ga_filtro") ? "formToolbar":"formToolbarInTab"}>
                <MostSubmitButton fullWidth={false} disabled={disabledButs} label={props.filterId === "__new__" ? t("Inserisci") : t("Modifica")} />
                <MostButton2 fullWidth={false} disabled={disabledButs} className="margin2416" onClick={back} label={props.modeFiltroForm === "updating" ? t("Annulla") : t("Indietro")} />
            </div>
        : null }
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <MostTextField name="name" disabled={disabledFields} required={true} label={t("Nome")} register={register({ required: true })} defaultValue={props.row ? props.row.name : null} errors={errors} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="description" disabled={disabledFields} required={true} label={t("Descrizione")} register={register({ required: true })} defaultValue={props.row ? props.row.description : null} errors={errors} inputProps={{ maxLength: 255 }}  />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="geotree" disabled label={t("Area Geografica")} defaultValue={props.row ? props.row.geotree : null} />
            {/*
              <MostAutocomplete control={control} name="geotreeid" disabled={disabledFields} options={props.options_geotreeid} label={t("Area")+" *"} defaultValue={geotreeid_defValue} required={true} rules={{ required: true }} errors={errors} />
            */}
            </Grid>
            <Grid item xs={12}>
              <MTabCampiCondizioni row={props.row} filterId={props.filterId} medical_field_category={props.medical_field_category} personal_fields={props.personal_fields} register={register} 
                resetAndOpenConditionDialog={resetAndOpenConditionDialog}
                condizioniAnagrafiche={condizioniAnagrafiche} condizioniMediche={condizioniMediche}
                setCondizioniAnagrafiche={setCondizioniAnagrafiche} setCondizioniMediche={setCondizioniMediche}
                allPersonalAndMedical={allPersonalAndMedical} allPersonalAndMedicalOnChange={allPersonalAndMedicalOnChange}
                allPersonal={allPersonal} allMedical={allMedical} allPersonalOnChange={allPersonalOnChange} allMedicalOnChange={allMedicalOnChange} defaultChecked={defaultChecked}
                checkedSimple={checkedSimple} setCheckedSimple={setCheckedSimple}
                sql_condition={sql_condition} setSql_condition={setSql_condition}
                appAlert={appAlert} modeFiltroForm={props.modeFiltroForm}
              />
            </Grid>
            { props.filterId !== "__new__" ? 
                <Grid item xs={12}>
                <MostTextField disabled label={t("Stato")} value={t(props.row.rstatus)} />
                </Grid>
            : null }
        </Grid>
      </form>
      <br />
    </div>
    <MFiltroFormConditionDialog
        openDialog={openDialog} handleCloseDialog={handleCloseDialog} handleSubmitDialog={handleSubmitDialog}
        options_medical={props.options_medical} options_personal={props.options_personal}
        setOptionsDialog={setOptionsDialog} optionsDialog={optionsDialog}
        errorsDialog={errorsDialog} setErrorsDialog={setErrorsDialog}
        conditionfieldtype={conditionfieldtype} setConditionfieldtype={setConditionfieldtype}
        valore_date={valore_date} setValore_date={setValore_date}
        operatore={operatore} setOperatore={setOperatore}
        options_valore={options_valore} setOptions_valore={setOptions_valore}
        options_operatore={options_operatore} setOptions_operatore={setOptions_operatore}
    />
    </div>
)}
export const MFiltroForm = React.memo(FiltroForm);
