import React, { useState, useCallback } from "react";
import log from 'loglevel';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { dmy_hms, ymd } from '../Utils';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { getOptionListElement, S3Field, MostAutocomplete, MostCheckbox, MostButton2, MostSubmitButton, MostTextField, MostDatePicker, MostTimePicker} from "../components/MostComponents";
import MyAxios, {check_response} from "../MyAxios";

  const ComunicazioneForm = (props) => {
      //log.debug("export Comunicazione",props.row)
      //log.debug(props)
      const history = useHistory();
      const [disabledButs, setDisabledButs] = useState(false)
      const { control, register, handleSubmit, errors, } = useForm();
      const { t } = useTranslation();
      const options_destinatari = [
        {value: "AttesaConsenso", label: t("pazienti che non hanno ancora espresso consenso")},
        {value: "ConsensoSI", label: t("pazienti che hanno aderito allo studio")},
      ]
      const [destinatari, setDestinatari] = useState(props.row ? getOptionListElement(options_destinatari,props.row.recipients) : null)
      const [docRemoved, setDocRemoved] = useState(false);
      const [uploadInfo, setUploadInfo] = useState(null);
      const [uploadTarget, setUploadTarget] = useState(null);
      let defDate = null
      if (props.row && props.row.date2send)
        defDate = new Date(props.row.date2send)
      const [sdate, setSdate] = useState(defDate)
      const [shour, setShour] = useState(defDate)
      const onChangeSdate = (date) => {
            setSdate(date)
      }
      const onChangeShour = (date) => {
            setShour(date)
      }
      const { setAlert1, setContent } = useGlobalHook('alertStore');
      const appAlert = useCallback((text) => {
          setContent(text);
          setAlert1(true);
      }, [setContent,setAlert1])

      const comunicazioneOnSubmit = (vals) => {
        log.debug("comunicazioneOnSubmit", vals)
        if (isNaN(sdate)) {
            appAlert(t("Data non valida"))
            return
        }
        if (isNaN(shour)) {
            appAlert(t("Ora non valida"))
            return
        }
        if (shour && !sdate) {
            appAlert(t("Data obbligatoria"))
            return
        }
        setDisabledButs(true)
        const formData = new FormData()
        formData.append('as_role', 'GlobalAdmin')
        formData.append('subject', vals.subject)
        formData.append('description', vals.description)
        formData.append('video_url', vals.video_url)
        if (uploadInfo) {
          formData.append('pdf_file', uploadInfo[0])
        } else {
            if(docRemoved) {
                formData.append('pdf_file', '')
            }
        }
        if (props.studioId) {
          formData.append('study_id', props.studioId)
          formData.append('recipients', destinatari.value)
          if (destinatari.value === "ConsensoSI")
            formData.append('sf36_request', vals.sf36_request)
        } else {
            formData.append('to_everybody', vals.to_everybody)
        }
        if (props.id === "__new__") {
            formData.append('action', 'ADD')
        } else {
            formData.append('action', 'MODIFY')
            formData.append('id', props.id)
        }
        if (sdate) {
            // facciamo gestire timezone a js
            let isodate = ymd(sdate)
            if (shour)
                isodate += 'T'+dmy_hms(shour).substring(0,5)
            else
                isodate += 'T00:00:00'
            const d = new Date(isodate)
            formData.append('date2send', d.toISOString())
        } else {
            // azzeramento data
            formData.append('date2send', '')
        }
        const url = "/communications"
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" }
        }).then((response) => {
            const res = check_response(response)
            if (res.success) {
                // se facciamo subito push da' errore il codice nel then finale che si trova senza la pagina corrente...
                setTimeout(() => {
                    back()
                }, 200)
            } else {
                log.error("Error: "+res.error);
                appAlert(res.error);
                setDisabledButs(false)
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
        .then(function () {
            // se cambia file uploadato, fallisce nuovo upload
            // con errore non significativo (Network Error)
            // mentre l'errore vero ERR_UPLOAD_FILE_CHANGED
            // viene restituito dopo in modo non catchabile
            // Resettiamo file dopo ogni upload
            if(uploadTarget)
              uploadTarget.value = null
            setUploadInfo(null)
        })
      }

      function back() {
        if (props.studioId)
            props.back()
        else
            history.push("/comunicazioni")
      }

      let disabled = false
      if (props.row && props.row.sent)
        disabled = true

      return (
      <form onSubmit={handleSubmit(comunicazioneOnSubmit)} noValidate>
        <Grid container spacing={1} alignItems="center">
            { props.studioId ?
                <React.Fragment>
                <Grid item xs={12}>
                    <MostTextField name="studio_id" disabled label={t("Codice studio")} defaultValue={props.studioId} />
                </Grid>
                    <Grid item xs={12}>
                <MostTextField disabled label={t("Titolo studio")} defaultValue={props.studioRow.title} />
                </Grid>
                </React.Fragment>
            : null}
            <Grid item xs={12}>
              <MostTextField name="subject" disabled={disabled} required={true} label={t("subject")} register={register({ required: true })} defaultValue={props.row ? props.row.subject : null} errors={errors} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="description" disabled={disabled} label={t("description")} register={register} defaultValue={props.row ? props.row.description : null} inputProps={{ maxLength: 4096 }} multiline={true} rows={5} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="video_url" disabled={disabled} label={t("video_url")} register={register} defaultValue={props.row ? props.row.video_url : null} inputProps={{ maxLength: 80 }} />
            </Grid>
            <Grid item xs={12}>
              <S3Field label={t("Documento PDF da allegare")} s3_id={props.row ? props.row.s3_id : null} showError={appAlert} docRemoved={docRemoved} setDocRemoved={setDocRemoved} setUploadInfo={setUploadInfo} setUploadTarget={setUploadTarget} readOnly={props.row && props.row.sent ? true : false} />
            </Grid>
            <Grid item xs={12}>
              <div className="displayFlex">
                <div className="w200 marginr4">
                  <MostDatePicker openTo="date" disabled={disabled} disableFuture={false} value={sdate} onChange={onChangeSdate} label={t("date2send")} />
                </div>
                <div className="w200">
                  <MostTimePicker value={shour} disabled={disabled} onChange={onChangeShour} label={t("time2send")} initialFocusedDate="2021-01-01 20:00" />
                </div>
              </div>
            </Grid>
            { props.studioId ?
                <React.Fragment>
                <Grid item xs={12}>
                  <MostAutocomplete name="destinatari" disabled={disabled} control={control} required={true} rules={{ required: true }}  errors={errors} options={options_destinatari} label={t("Destinatari")+" *"} onChange={(e,el) => setDestinatari(el)} defaultValue={destinatari} />
                </Grid>
                <Grid item xs={12}>
                  <div className="boxForm no_v_padding">
                    <MostCheckbox label={t("Invito a compilazione SF36")} name="sf36_request" register={register} defaultChecked={props.row ? props.row.sf36_request : null} disabled={disabled || !destinatari || destinatari.value !== "ConsensoSI"} />
                  </div>
                </Grid>
                </React.Fragment>
            : 
                <Grid item xs={12}>
                  <div className="boxForm no_v_padding">
                    <MostCheckbox label={t("Da mandare a tutti")} name="to_everybody" register={register} defaultChecked={props.row ? props.row.to_everybody : null} disabled={disabled} />
                  </div>
                </Grid>
            }
            { props.id !== "__new__" ?
                <React.Fragment>
                <Grid item xs={12}>
                    <div className="boxForm no_v_padding">
                        <MostCheckbox disabled label={t("Comunicazione già inviata")} checked={props.row ? props.row.sent : null} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                  <MostTextField disabled label={t("Stato")} value={t(props.row.rstatus)} />
                </Grid>
                </React.Fragment>
            : null }
            <Grid item xs={8}>
                <MostSubmitButton id="submit" disabled={disabled ? true : disabledButs} label={props.id === "__new__" ? t("Inserisci") : t("Modifica")} />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 id="back" fullWidth disabled={disabledButs} className="margin2416" onClick={back} label={t("Indietro")} />
            </Grid>
        </Grid>
      </form>
      )
  }

export const Comunicazione = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
  }

  let title
  if (props.id === "__new__") {
    title = t("Nuova comunicazione")
  } else {
    title = t("Gestione comunicazione")+" "+props.id
  }

  if (props.studioId) {
    // nuova comunicazione dello studio props.studioId props.studioRow.title
    return (
        <div className="blackColor">  
        <Container component="main" maxWidth="md">
            <h3> {title} </h3>
            <ComunicazioneForm studioId={props.studioId} studioRow={props.studioRow} back={props.back} id={props.id} row={props.row} />
        </Container>
        </div>
    )
  }

  function suspendResume() {
    const formData = new FormData()
    formData.append('as_role', 'GlobalAdmin')
    formData.append('id', props.id)
    formData.append('action', props.row.rstatus === "SUSPEND" ? "RESUME" : "SUSPEND")
    const url = "/communications"
    log.debug(url, [...formData.entries()])
    MyAxios.post(url, formData)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            appAlert2(t("Stato modificato"),() => {
                history.push("/comunicazioni")
            })
        } else {
            log.error("Error: "+res.error);
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }

  return (
  <div className="app-container">
  <Header title={title} backMode="/comunicazioni" />
  <div className="content-container">
  <Container component="main" maxWidth="md">
    { props.id !== "__new__" ? 
        <Toolbar className="rowreverse">
            <Button variant="contained" color="primary" className="marginr10" onClick={suspendResume} > {props.row.rstatus === "SUSPEND" ? t("Riattiva") : t("Disabilita")} </Button>
            {/*
            <Button disabled variant="contained" color="primary" className="marginr10" > {t("Cancella")} </Button>
            */}
        </Toolbar>
    : null }
    <div className="blackColor">
      <ComunicazioneForm id={props.id} row={props.row} />
    </div>
  </Container>
  </div>
  <Footer />
  </div>
  )
}
