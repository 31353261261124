import React, { useEffect, useCallback, } from "react";
import log from 'loglevel';
import MyAxios, { check_response, } from "./MyAxios";
import Grid from "@material-ui/core/Grid";
import { MostTextField, MostButton } from "./components/MostComponents";
import { Header } from './Header';
import { Footer } from './Footer';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { dmy_hms, showUrl, showDoc, } from './Utils'
import { isPatient } from './UtilsInthem'

// sorgente usato anche per gli utenti
export const PComunicazione = (props) => {
    //log.debug(props.row)
    const { userInfo } = useGlobalHook('userStore');
    const { t, } = useTranslation();
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    useEffect(() => {
        if (!isPatient(userInfo))
            return
        MyAxios.get("/get_communication/"+props.id
        ).then((response) => {
          const data = check_response(response);
          if(!data.success) {
            log.error(data.error)
            appAlert(data.error)
            return
          }
        })
        .catch(function (error) {
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
        })
    }, [appAlert,props.id,userInfo])

    const data = dmy_hms(new Date(props.row.date2send),2)
    // title={t("Newsletter del")+" "+data} 
    return (
  <div className="app-container">
    <Header backMode="/pcomunicazioni" />
    <div className="content-container" >
    <Container component="main" maxWidth="sm">
        <div className="blackColor">
            { props.row.study_id ? 
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <MostTextField disabled label={t("Codice studio")} defaultValue={props.row.study_id} />
                    </Grid>
                        <Grid item xs={12}>
                    <MostTextField disabled label={t("Titolo studio")} defaultValue={props.row.study.title} />
                    </Grid>
                </Grid>
            : null }
            <h1> {props.row.subject} </h1>
            <p> {props.row.description} </p>
            <br /> <br />
            <Grid container spacing={1} alignItems="center">
            { props.row.s3_id ? 
                <React.Fragment>
                <Grid item xs={12}>
                    <MostButton onClick={() => showDoc(props.row.s3_id)} label={t("Visualizza il documento")} />
                </Grid>
                {/*
                <Grid item xs={12} sm={6}>
                    <MostButton onClick={() => downloadDoc(props.row.s3_id)} label={t("Scarica il documento")} />
                </Grid>
                */}
                </React.Fragment>
            : null }
            { props.row.video_url ?
                <Grid item xs={12}>
                    <MostButton onClick={() => showUrl(props.row.video_url)} label={t("Visualizza il video")} /> 
                </Grid>
            : null }
            </Grid>
            <br /> <br />
            <div className="tright">
            {t("Pubblicato il")} {data} <br />
            </div>
        </div>
    </Container>
    </div>
    <Footer />
  </div>
    );
};
