import React, { useEffect, useCallback, useState } from "react";
import log from 'loglevel';
import Container from "@material-ui/core/Container";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useTranslation } from 'react-i18next';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useHistory } from "react-router-dom";
import { options_tabelleservizio, options_formatofile_download, authorized, } from '../UtilsInthem'
import { dataGridMakeIdUnique, } from '../Utils'
import MyAxios, { check_and_download, } from "../MyAxios";
import { getOptionListElement, MostAutocomplete, MostButton2, MostSubmitButton } from "../components/MostComponents";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { DataTable } from '../components/DataTable';

export const DownloadDiagnosiTipichelanti = (props) => {
    //log.debug("TabelleServizio")
    const { userInfo } = useGlobalHook('userStore');
    const history = useHistory();
    const { control, handleSubmit, errors, } = useForm();
    const { t, } = useTranslation();
    const [options_formatofile_download_t] = useState(options_formatofile_download(t))
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [showdatagrid, setShowdatagrid] = useState(false)
    const [disabledButs, setDisabledButs] = useState(false)
    const { setAlert1, setContent } = useGlobalHook('alertStore');
    const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
    }, [setContent,setAlert1])

    const options_tabelle = [
        getOptionListElement(options_tabelleservizio, 'chelating_drug'),
        getOptionListElement(options_tabelleservizio, 'specific_diagnosis'),
    ]

    useEffect(() => {
        for (let i in userInfo.roles)
            if (userInfo.roles[i].role === "CenterAdmin") {
                //setCenter(userInfo.roles[i].center)
                break
            }
    }, [userInfo.roles])

    const onSubmitDownload = (vals) => {
        log.debug("onSubmitDownload vals",vals)
        setShowdatagrid(false)
        let format = vals.format.value
        if (vals.format.value === "video")
            format = 'JSON'
        const formData = new FormData()
        let url
        if (vals.tablename.value === "chelating_drug")
            url = "/getICTproducts"
        else
            url = "/getDiagnosis"
        formData.append('format', format)
        log.debug(url, [...formData.entries()])
        MyAxios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data;" },
            responseType: format !== 'JSON' ? 'arraybuffer' : '',
        }).then((response) => {
            setDisabledButs(false)
            const fileName = vals.tablename.value+'.'+format.toLowerCase()
            const res = check_and_download(response,format,vals.format.value,fileName,1)
            if(res.success === false) {
                log.error("Error: "+res.error)
                appAlert(res.error)
                return
            }
            if (vals.format.value === "video") {
                setRows([])
                setShowdatagrid(true)
                setColumns(vals.tablename.columns)
                // tabelle con campo ID non chiave? data-grid si arrabbia
                // bisogna creare id come chiave e rinominare campo id
                if(vals.tablename.idIsNotKey)
                    setRows(dataGridMakeIdUnique(res.rows))
                else if(vals.tablename.missingId)
                    setRows(dataGridMakeIdUnique(res.rows,1))
                else
                    setRows(res.rows)
            }
        })
        .catch(function (error) {
            // handle error
            log.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setDisabledButs(false)
        })
    }

    const back = () => {
        history.push('/uhome');
    }

    const redirect = authorized("CenterAdmin",userInfo)
    if(redirect)
        return redirect

    const onSubmit = onSubmitDownload
    const buttonLabel = t("Recupera")

    return (
    <div className="app-container">
        <Header title={t("Download Diagnosi e Tipi chelanti")} backMode="/globalconfig" />
        <div className="content-container contentWithToolbar">
          <div>
          <Container component="main" maxWidth="md">
                <div className="margintop30">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={1} alignItems="center" className="blackColor">
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="tablename" options={options_tabelle} label={t("Tabella")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <MostAutocomplete control={control} name="format" options={options_formatofile_download_t} label={t("Formato file")+" *"} required={true} rules={{ required: true }}  defaultValue={null} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={8}>
                            <MostSubmitButton disabled={disabledButs} className="b_loginok" label={buttonLabel} />
                        </Grid>
                        <Grid item xs={4}>
                            <MostButton2 fullWidth disabled={disabledButs} className="b_loginok" onClick={back} label={t("Indietro")} />
                        </Grid>
                    </Grid>
                    </form>
                </div>
          </Container>
          </div>
          <div className="margintop30">
            { showdatagrid ?
                <DataTable columns={columns} rows={rows} height={600} density={"compact"} />
            : null }
          </div>
        </div>
        <Footer />
    </div>
    )
}
