import React, { useState, useCallback, useEffect } from "react";
import log from 'loglevel';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { getOptionListElement, MostAutocomplete, MostTextField, MostButton2, Loading, MostSubmitButton, } from "../components/MostComponents";
import MyAxios, { check_response } from "../MyAxios";

export const Config = (props) => {
  const { t } = useTranslation();
  const [ strength_options ] = useState([
    { label: t("Nessun controllo"), value: 0 },
    { label: t("cifre simboli maiuscole minuscole"), value: 1 },
  ])
  const history = useHistory();
  const { register, control, handleSubmit, errors, } = useForm();
  const [def, setDef] = useState(null)
  const [disabledButs, setDisabledButs] = useState(false)
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])

  useEffect(() => {
    const jdata = {
        action: 'LIST',
    }
    MyAxios.post("/admin/configuration", jdata)
    .then((response) => {
      const data = check_response(response);
      if(!data.success) {
        log.error(data.error)
        appAlert(data.error)
        return
      }
      if(typeof data.row.password_min_strength === 'number')
        data.row.password_min_strength = getOptionListElement(strength_options,data.row.password_min_strength)
      else
        data.row.password_min_strength = null
      setDef(data.row)
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }, [appAlert, strength_options])

  const onSubmit = (vals) => {
    log.debug("onSubmit", vals)
    setDisabledButs(true)
    const jdata = {
        action: 'CHANGE',
        otp_minute_duration: parseInt(vals.otp_minute_duration),
        password_day_duration: parseInt(vals.password_day_duration),
        password_min_length: parseInt(vals.password_min_length),
        password_min_strength: vals.password_min_strength.value,
    }
    MyAxios.post("/admin/configuration", jdata)
    .then((response) => {
        setDisabledButs(false)
        const res = check_response(response)
        if (res.success) {
            appAlert("Dati salvati correttamente")
        } else {
            log.error("Error: "+res.error)
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        log.error(error)
        appAlert(error.message?error.message:JSON.stringify(error))
        setDisabledButs(false)
    })
  }

  let title = t("Configurazione parametri")
  if (!def) {
    return <Loading />
  }
  console.log(errors)

  /*
   * NOTA: form noValidate: non si hanno controlli min-max del browser !
   * se non si mette noValidate, controlli su required da parte del browser e non da form react (1 campo per volta e con pannellino nativo del browser)
   * min max : metterli in inputProps blocca interfaccia del browser per scendere e salire nei numeri, ma non blocca inserimento da input; metterli in register fa fare i controlli alla form react
   * maxLength non e' un problema perche' viene impedito di inserire piu' dei caratterei previsti
   */
  return (
  <div className="app-container">
  <Header title={title} backMode="/globalconfig" />
  <div className="content-container">
  <Container component="main" maxWidth="sm">
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <MostTextField name="otp_minute_duration" required={true} 
                label={t("otp_minute_duration")}
                inputProps={{type:'number',min:1}} 
                register={register({ required:true, min:1})} 
                defaultValue={def.otp_minute_duration} errors={errors} 
              />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="password_day_duration" required={true} 
                label={t("password_day_duration")}
                inputProps={{type:'number',min:0}} 
                register={register({ required:true, min:0 })} 
                defaultValue={def.password_day_duration} errors={errors} 
              />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="password_min_length" required={true} 
                label={t("password_min_length")}
                inputProps={{type:'number',min:0}} 
                register={register({ required:true, min:0 })} 
                defaultValue={def.password_min_length} errors={errors} 
              />
            </Grid>
            <Grid item xs={12}>
              <MostAutocomplete name="password_min_strength" required={true} 
                label={t("password_min_strength")+" *"}
                options={strength_options}
                control={control} rules={{ required: true }}
                defaultValue={def.password_min_strength} errors={errors} 
              />
            </Grid>
            <Grid item xs={8}>
                <MostSubmitButton disabled={disabledButs} label={props.userId === "__new__" ? t("Inserisci") : t("Modifica")} />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} className="margin2416" onClick={() => history.push("/globalconfig")} label={t("Indietro")} />
            </Grid>
        </Grid>
      </form>
    </div>
  </Container>
</div>
  <Footer />
  </div>
)
}

