
export function MobileTitle(props) {
    return (
        <div className={"mobileonly mobiletitle "+props.className}>
            { props.title ?
                <span> {props.title+" "}
                    { props.subtitle ?
                        <span className="piccolo">{props.subtitle}</span>
                    : null }
                </span>
            : null }
        </div>
    )
}
