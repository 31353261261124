import React, { useState, useCallback, useEffect } from "react";
import log from 'loglevel';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import FormLabel from "@material-ui/core/FormLabel";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { MostButton2, MostAutocomplete, MostCheckbox, Loading, MostSubmitButton, MostTextField, } from "../components/MostComponents";
import MyAxios, { check_response } from "../MyAxios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const Utente = (props) => {
  //log.debug("Utente",props.row)
  //log.debug("Utente",props.options_centri)
  //log.debug("Utente",props.options_aree)
  const history = useHistory();
  const [disabledButs, setDisabledButs] = useState(false)
  const [dialogTitle, setDialogTitle] = useState("")
  const [dialogValue, setDialogValue] = useState("")
  const [globalAdmin, setGlobalAdmin] = useState(null)
  const [userAdmin, setUserAdmin] = useState(null)
  const [dataManager, setDataManager] = useState(null)
  const [centerAdmin, setCenterAdmin] = useState(null)
  const { register, handleSubmit, errors, } = useForm();
  const { t } = useTranslation();
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
      setContent(text);
      setAlert1(true);
  }, [setContent,setAlert1])
  const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
  function appAlert2(text,closeHandler) {
      setContent2(text)
      setOnClose2(() => x => {
        closeHandler()
      });
      setAlert2(true)
  }
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if(!props.row)
        return
    for (let i in props.row.user_roles)
        if(props.row.user_roles[i].role === 'GlobalAdmin') {
            setGlobalAdmin(props.row.user_roles[i])
        } else if(props.row.user_roles[i].role === 'UserAdmin') {
            setUserAdmin(props.row.user_roles[i])
        } else if(props.row.user_roles[i].role === 'DataManager') {
            setDataManager(props.row.user_roles[i])
        } else if(props.row.user_roles[i].role === 'CenterAdmin') {
            setCenterAdmin(props.row.user_roles[i])
        }
  }, [props.row])

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    if (!dialogValue) {
        appAlert(t("campo obbligatorio"))
        return
    }
    //appAlert(dialogTitle+" "+dialogValue.value)
    if(dialogTitle === 'GlobalAdmin') {
        setGlobalAdmin({role:dialogTitle, areaid:dialogValue.value, center:null})
    } else if(dialogTitle === 'UserAdmin') {
        setUserAdmin({role:dialogTitle, areaid:dialogValue.value, center:null})
    } else if(dialogTitle === 'DataManager') {
        setDataManager({role:dialogTitle, areaid:dialogValue.value, center:null})
    } else if(dialogTitle === 'CenterAdmin') {
        setCenterAdmin({role:dialogTitle, center:dialogValue.value, areaid:dialogValue.row.geotreeid})
    }
    setOpen(false)
  }

  const cbLabel = (name,row) => {
    return name + " " + (row && row.center ? row.center : (row && row.areaid ? row.areaid : ""))
  }

  const roleOnChange = (role,roleVar,setRoleVar) => {
    if(!roleVar) {
        setDialogTitle(role)
        setDialogValue("")
        setOpen(true)
    } else {
        setRoleVar(null)
    }
  }

  const globalAdminOnChange = (e) => {
    roleOnChange('GlobalAdmin',globalAdmin,setGlobalAdmin)
  }

  const userAdminOnChange = (e) => {
    roleOnChange('UserAdmin',userAdmin,setUserAdmin)
  }

  const dataManagerOnChange = (e) => {
    roleOnChange('DataManager',dataManager,setDataManager)
  }

  const centerAdminOnChange = (e) => {
    roleOnChange('CenterAdmin',centerAdmin,setCenterAdmin)
  }

  const onSubmit = (vals) => {
    log.debug("onSubmit", vals)
    const roles = []
    if (globalAdmin)
        roles.push(globalAdmin)
    if (userAdmin)
        roles.push(userAdmin)
    if (dataManager)
        roles.push(dataManager)
    if (centerAdmin)
        roles.push(centerAdmin)
    //log.debug("roles", roles)
    setDisabledButs(true)
    let action
    let id = null
    if (props.userId === "__new__")
        action = 'ADD'
    else {
        action = 'CHANGE'
        id = props.userId
    }
    const jdata = {
        action: action,
        id: id,
        name: vals.name,
        surname: vals.surname,
        email: vals.email,
        user_roles: roles
    }
    //log.debug("jdata",jdata)
    MyAxios.post("/admin/user", jdata)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            history.push('/utenti/');
        } else {
            log.error("Error: "+res.error);
            appAlert(res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
  }

  let title
  if (props.userId === "__new__") {
    title = t("Nuovo utente")
  } else {
    if (!props.row) {
        // dobbiamo costruire form solo dopo che abbiamo tutti i dati
        // altrimenti vengono presi defaultValue nulli
        // che non vengono piu' modificati da successivi richiami con defaultValue significativi
        return <Loading />
    }
    title = t("Gestione utente")+" "+props.row.name+" "+props.row.surname
  }

  function suspendResume() {
    const jdata = {
        action: props.row.rstatus === "SUSPEND" ? "RESUME" : "SUSPEND",
        id: props.userId,
    }
    MyAxios.post("/admin/user", jdata)
    .then((response) => {
        const res = check_response(response)
        if (res.success) {
            appAlert2(t("Stato modificato"),() => {
                history.push("/utenti")
            })
        } else {
            log.error("Error: "+res.error);
            appAlert(res.error);
        }
    })
    .catch(function (error) {
        log.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    })
  }

  return (
  <div className="app-container">
  <Header title={title} backMode="/utenti" />
  <div className="content-container">
  <Container component="main" maxWidth="md">
    { props.userId !== "__new__" ? 
        <Toolbar className="rowreverse">
            <Button variant="contained" color="primary" className="marginr10" onClick={suspendResume} > {props.row.rstatus === "SUSPEND" ? t("Riattiva") : t("Disabilita")} </Button>
            {/*
            <Button disabled variant="contained" color="primary" className="marginr10" > {t("Cancella")} </Button>
            */}
        </Toolbar>
    : null }
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <MostTextField name="surname" required={true} label={t("Cognome")} register={register({ required: true })} defaultValue={props.row ? props.row.surname : null} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="name" required={true} label={t("Nome")} register={register({ required: true })} defaultValue={props.row ? props.row.name : null} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <MostTextField name="email" required={true} label={t("Email")} register={register({ required: true })} defaultValue={props.row ? props.row.email : null} errors={errors} />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("Ruoli")}</FormLabel>
              <Box border={1} borderRadius={4} className="box" >
                <div className="blackColor padding10">
                    <MostCheckbox label={cbLabel('GlobalAdmin',globalAdmin)} checked={globalAdmin?true:false} onChange={globalAdminOnChange} />
                    <br />
                    <MostCheckbox label={cbLabel('UserAdmin',userAdmin)} checked={userAdmin?true:false} onChange={userAdminOnChange} />
                    <br />
                    <MostCheckbox label={cbLabel('DataManager',dataManager)} checked={dataManager?true:false} onChange={dataManagerOnChange} />
                    <br />
                    <MostCheckbox label={cbLabel('CenterAdmin',centerAdmin)} checked={centerAdmin?true:false} onChange={centerAdminOnChange} />
                </div>
              </Box>
            </Grid>
            { props.userId !== "__new__" ? 
                <Grid item xs={12}>
                  <MostTextField disabled label={t("Stato")} value={t(props.row.rstatus)} />
                </Grid>
            : null }
            <Grid item xs={8}>
                <MostSubmitButton disabled={disabledButs} label={props.userId === "__new__" ? t("Inserisci") : t("Modifica")} />
            </Grid>
            <Grid item xs={4}>
                <MostButton2 fullWidth disabled={disabledButs} className="margin2416" onClick={() => history.push("/utenti")} label={t("Indietro")} />
            </Grid>
        </Grid>
      </form>
    </div>
  </Container>
</div>
  <Footer />
  <Dialog id="form-dialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
    <DialogContent>
        <MostAutocomplete 
            name="select" 
            options={dialogTitle === "CenterAdmin" ? props.options_centri : props.options_aree}
            label={dialogTitle === "CenterAdmin" ? t("Centro") : t("Area")}
            value={dialogValue} 
            defaultValue={null}
            onChange={(event, newValue) => {
                setDialogValue(newValue);
        }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="outlined" color="secondary">
        {t("Annulla")}
      </Button>
      <Button onClick={handleConfirm} variant="contained" color="primary">
        {t("Conferma")}
      </Button>
    </DialogActions>
  </Dialog>
  </div>
)
}

